import React, { useState, useEffect } from "react"
import { Modal, Form, Select } from "antd"
import { AppraisalClient, AppraisalTemplateClient } from "@api"
import { FormattedMessage } from "react-intl"
import { errorMessage } from "@action"
import { showSelectProps } from "@object"

const TemplateModal = ({ visible, onCancel, id, templateId }) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState([])
  const { Option } = Select

  const onFinish = async values => {
    setIsLoading(true)
    const client = new AppraisalClient()
    try {
      await client.changeTemplate({
        id: id,
        ...values,
      })
      onCancel()
      window.location.reload()
    } catch (err) {
      errorMessage("Change Template", err)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const getOptions = async () => {
      const templateClient = new AppraisalTemplateClient()
      const templateResult = await templateClient.getOptions(
        true,
        null,
        "id",
        true
      )
      setOptions(templateResult)
    }
    if (visible) getOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  return (
    <Modal
      forceRender
      width="700px"
      centered
      confirmLoading={isLoading}
      visible={visible}
      cancelText={<FormattedMessage id="cancel" />}
      okText={<FormattedMessage id="change" />}
      onCancel={onCancel}
      destroyOnClose={true}
      onOk={() => form.submit()}
      title={<FormattedMessage id="changeTemplate" />}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          name="templateId"
          label={<FormattedMessage id="template" />}
          rules={[
            {
              required: true,
              message: "Please select a template. 請輸入新範本",
            },
          ]}
          initialValue={templateId}
        >
          <Select {...showSelectProps}>
            {options.map(item => (
              <Option key={item.id} value={item.id}>
                {`${item.id}. ${item.name}`}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}
export default TemplateModal
