import React, { useEffect, useState, useCallback } from "react"
import { Table } from "antd"
import { FormattedMessage } from "react-intl"
import { AppraisalClient } from "@api"

const PastAppraisalTable = React.memo(({ id }) => {
  const [dataSource, setDataSource] = useState([])
  const columns = [
    {
      title: <FormattedMessage id="appraisalCycleName" />,
      dataIndex: "appraisalCycleName",
    },
    {
      title: <FormattedMessage id="finalScore2" values={{ name: "" }} />,
      dataIndex: "finalScore",
    },
  ]

  const fetchData = useCallback(() => {
    const callAPI = async appraisalId => {
      try {
        const params = {
          appraisalId: appraisalId,
          pageSize: 3,
          pageIndex: 1,
        }
        const client = new AppraisalClient()
        const result = await client.getLatestAppraisalsQuery(
          params.appraisalId,
          params.pageSize,
          params.pageIndex
        )
        let newList = result.items
        setDataSource(newList)
      } catch (error) {
        console.log(error)
      }
    }
    if (!!id) callAPI(id)
  }, [id])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <Table
      rowKey="id"
      size="small"
      pagination={false}
      columns={columns}
      dataSource={dataSource}
    />
  )
})

export default PastAppraisalTable
