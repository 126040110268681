import React, { useContext } from "react"
import { Descriptions, Divider, Row, Col, Tag } from "antd"
import "./current_score.less"
import { translate } from "@action"
import { SyncOutlined } from "@ant-design/icons"
import { FormattedMessage } from "react-intl"
import { ContextStore } from "@context"

const CurrentScore = ({
  score,
  isNormal,
  degree360Weighting,
  coreCompetencyWeighting,
  siteKPIWeighting,
}) => {
  const { locale } = useContext(ContextStore)
  return (
    <div className="current-score">
      <Row className="score-row">
        <Divider orientation="left">
          <FormattedMessage id="currentScore" values={{ name: "" }} />
        </Divider>
        <Col span={7}>
          <Descriptions
            title={
              <>
                <FormattedMessage id="coreCompetencies" />
                {" (" + coreCompetencyWeighting + "%)"}
              </>
            }
            column={1}
          >
            <Descriptions.Item label={<FormattedMessage id="score" />}>
              {score
                ? score.coreCompetencyScore +
                  " / " +
                  score.coreCompetencyMaxScore
                : null}
            </Descriptions.Item>
            {score &&
              isNormal &&
              score.scoreAdjustmentGroup.map((item, index) => (
                <Descriptions.Item
                  key={index}
                  label={translate(item.name, locale)}
                >
                  {item.totalScore}
                </Descriptions.Item>
              ))}
          </Descriptions>
        </Col>
        <Col span={1}>
          <Divider type="vertical" plain className="info-divider"></Divider>
        </Col>

        {score &&
          (score.siteKPIMaxScore > 0 || score.isSiteKPIPending) &&
          isNormal && (
            <>
              <Col span={7}>
                <Descriptions
                  title={
                    <>
                      <FormattedMessage id="siteKPI" />
                      {" (" + siteKPIWeighting + "%)"}
                    </>
                  }
                  column={1}
                >
                  <Descriptions.Item label={<FormattedMessage id="score" />}>
                    {!score.isSiteKPIPending ? (
                      score.siteKPIScore + " / " + score.siteKPIMaxScore
                    ) : (
                      <FormattedMessage id="tbc" />
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </Col>

              <Col span={1}>
                <Divider
                  type="vertical"
                  className="info-divider"
                  plain
                ></Divider>
              </Col>
            </>
          )}

        {score && score.degree360MaxScore > 0 && isNormal && (
          <>
            <Col span={7}>
              <Descriptions
                title={
                  <Row align="space-between">
                    <Col>
                      <FormattedMessage id="degree360" />
                      {" (" + degree360Weighting + "%)"}
                    </Col>

                    {!score.isDegree360Completed && (
                      <Col>
                        <Tag icon={<SyncOutlined />} color="processing">
                          <FormattedMessage id="inProgress" />
                        </Tag>
                      </Col>
                    )}
                  </Row>
                }
                column={1}
              >
                <Descriptions.Item label={<FormattedMessage id="score" />}>
                  {score.degree360Score + " / " + score.degree360MaxScore}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={1}>
              <Divider type="vertical" className="info-divider" plain></Divider>
            </Col>
          </>
        )}
      </Row>
      <Row className="score-row">
        <Col span={7}>
          <Descriptions column={1}>
            <Descriptions.Item
              className="sub-score"
              label={<FormattedMessage id="subtotal" />}
            >
              {score?.coreCompetencySubtotal}
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={1}>
          <Divider type="vertical" plain className="info-divider"></Divider>
        </Col>
        {score &&
          (score.siteKPIMaxScore > 0 || score.isSiteKPIPending) &&
          isNormal && (
            <>
              <Col span={7}>
                <Descriptions column={1}>
                  <Descriptions.Item
                    className="sub-score"
                    label={<FormattedMessage id="subtotal" />}
                  >
                    {score.siteKPIMaxScore > 0 ? score.siteKPISubtotal : null}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Col span={1}>
                <Divider
                  type="vertical"
                  plain
                  className="info-divider"
                ></Divider>
              </Col>
            </>
          )}
        {score && score.degree360MaxScore > 0 && isNormal && (
          <>
            <Col span={7}>
              <Descriptions column={1}>
                <Descriptions.Item
                  className="sub-score"
                  label={<FormattedMessage id="subtotal" />}
                >
                  {score.degree360Subtotal}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={1}>
              <Divider type="vertical" className="info-divider" plain></Divider>
            </Col>
          </>
        )}

        <Divider orientation="right" className="score-divider">
          <FormattedMessage id="currentScore" values={{ name: ": " }} />
          {score?.finalScore}
        </Divider>
      </Row>
    </div>
  )
}

export default CurrentScore
