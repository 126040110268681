import React, { useEffect, useState, useContext } from "react"
import moment from "moment"
import {
  Divider,
  Row,
  Col,
  Form,
  Input,
  Comment,
  DatePicker,
  Upload,
  Button,
  message,
  Typography,
  Empty,
} from "antd"
import {
  UploadOutlined,
  LoadingOutlined,
  CheckCircleTwoTone,
} from "@ant-design/icons"
import "./comments.less"
import {
  translate,
  getPrincipleName,
  getFiles,
  transformDate,
  getPrincipleType,
} from "@action"
import { AppraisalCommentClient } from "@api"
import { FormattedMessage, useIntl } from "react-intl"
import { ContextStore } from "@context"
import { errorHandle } from "@action"

const getInputRecord = (arr, defId, staffNo, isSame) => {
  let newArr = arr.find(
    item =>
      item.commentDefinitionId === defId &&
      (item.staff.staffNo === staffNo) === isSame
  )

  return typeof newArr !== "undefined" ? newArr : null
}

const handleDownload = async file => {
  let client = new AppraisalCommentClient()
  try {
    let res = await client.attachment(file.uid)
    let url = window.URL.createObjectURL(res.data)
    let a = document.createElement("a")
    a.href = url
    a.download = res.fileName
    a.click()
    a.remove()
  } catch (err) {
    errorHandle()
  }
}

const InputArea = ({ type, index, appraisalId, commentId, history }) => {
  const { Text } = Typography
  const [isTouch, setIsTouch] = useState(false)
  const [content, setContent] = useState(history ? history.content : null)
  const [file, setFile] = useState(history ? getFiles(history) : [])
  const [updateId, setUpdateId] = useState(history ? history.id : null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [saveTime, setSaveTime] = useState(null)
  const { TextArea } = Input
  const { formatMessage } = useIntl()
  const commentPlaceholder = formatMessage({ id: "textAreaReviewComment" })
  const dataPlaceholder = formatMessage(
    { id: "selectPlaceholder" },
    { name: formatMessage({ id: "date" }) }
  )
  const dataWarning = formatMessage(
    { id: "pleaseSelect" },
    { name: formatMessage({ id: "date" }) }
  )

  const onRemove = async () => {
    let client = new AppraisalCommentClient()
    try {
      await client.delete(updateId)
      setUpdateId(null)
    } catch (err) {
      errorHandle()
    }
  }

  const onChange = ({ file: newFile, fileList: newFileList }) => {
    const isValid =
      newFile.type === "image/png" ||
      newFile.type === "image/gif" ||
      newFile.type === " image/jpg" ||
      newFile.type === "image/jpeg" ||
      newFile.type === "application/pdf" ||
      newFile.type === "text/plain"
    const isLt5M = newFile.size / 1024 / 1024 < 5
    if (
      (newFile.status && newFile.status === "removed") ||
      (isLt5M && isValid)
    ) {
      setIsTouch(true)
      console.log(newFileList)
      setFile([...newFileList])
    } else if (!isValid) {
      message.error("The file type of " + newFile.name + " is not accepted")
    } else if (!isLt5M) {
      message.error(newFile.name + " cannot be larger than 5 Mbs")
    }
  }

  const handleSubmit = async () => {
    if (!!content || (type === 2 && file.length > 0)) {
      setIsSubmitting(true)
      let client = new AppraisalCommentClient()
      let result
      let submitFile =
        file.length > 0
          ? { data: file[0].originFileObj, fileName: file[0].name }
          : null
      try {
        if (updateId === null) {
          result = await client.create(
            type === 1 ? content.toJSON() : content,
            commentId,
            appraisalId,
            submitFile
          )
          console.log("submit Data")
          setUpdateId(result.content)
          setIsTouch(false)
          if (submitFile) {
            setFile(
              getFiles({ attachment: submitFile.fileName, id: result.content })
            )
          }
        } else {
          result = await client.update(
            updateId,
            type === 1 ? content.toJSON() : content,
            submitFile
          )
          console.log("Update Data")
        }
      } catch (err) {
        errorHandle()
      } finally {
        const obj = {
          date: moment().format("YYYY-MM-DD"),
          time: moment().format("HH:mm:ss"),
        }
        setSaveTime(obj)
        setIsSubmitting(false)
      }
    }
  }

  useEffect(() => {
    if (isTouch && (!!content || type === 2)) {
      handleSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, file])

  return (
    <>
      {type === 0 ? (
        <Form.Item
          name={[index, "comment"]}
          onBlur={e => {
            setIsTouch(true)
            setContent(e.target.value)
          }}
          initialValue={content}
          rules={[{ required: true, message: commentPlaceholder }]}
        >
          <TextArea
            disabled={isSubmitting}
            autoSize={{ minRows: 5, maxRows: 10 }}
            placeholder={commentPlaceholder}
          />
        </Form.Item>
      ) : type === 1 ? (
        <Form.Item
          name={[index, "comment"]}
          initialValue={content ? moment(content) : null}
          rules={[{ required: true, message: dataWarning }]}
        >
          <DatePicker
            disabled={isSubmitting}
            placeholder={dataPlaceholder}
            format="DD MMM YYYY"
            onChange={e => {
              setIsTouch(true)
              setContent(e)
            }}
          />
        </Form.Item>
      ) : type === 2 ? (
        <Form.Item
          name={[index, "Attachment"]}
          extra={
            <Text type="secondary">
              <FormattedMessage id="maxFileSize" values={{ name: "5MB" }} />
              <br />
              <FormattedMessage
                id="fileType"
                values={{ name: "png gif jpg jpeg pdf txt" }}
              />
            </Text>
          }
          initialValue={file}
        >
          <Upload
            size="small"
            beforeUpload={() => false}
            onPreview={handleDownload}
            onChange={onChange}
            fileList={file}
            accept=".png,  .gif, .jpg, .jpeg, .pdf, .txt"
            onRemove={onRemove}
          >
            {file && file.length < 1 && (
              <Button disabled={isSubmitting}>
                <UploadOutlined />
                <FormattedMessage
                  id="upload"
                  values={{ name: <FormattedMessage id="attachment" /> }}
                />
              </Button>
            )}
          </Upload>
        </Form.Item>
      ) : (
        <></>
      )}{" "}
      <Row gutter={[10, 10]} justify="end">
        {isSubmitting ? (
          <LoadingOutlined spin />
        ) : (
          <>
            <Col>
              {saveTime ? (
                <FormattedMessage id="lastSavedOn" values={saveTime} />
              ) : null}
            </Col>
            <Col>
              <CheckCircleTwoTone twoToneColor="#52c41a" />
            </Col>
          </>
        )}
      </Row>
    </>
  )
}

const CommentReviewArea = ({ type, id, record }) => {
  let list = record.filter(item => item.commentDefinitionId === id)

  return list && list.length > 0 ? (
    list.map(item => (
      <Comment
        author={item.staff.name}
        content={
          type === 0 ? (
            <pre>{item.content}</pre>
          ) : type === 1 ? (
            transformDate(item.content)
          ) : (
            <Upload
              size="small"
              onPreview={handleDownload}
              showUploadList={{ showRemoveIcon: false }}
              fileList={getFiles(item)}
            />
          )
        }
      />
    ))
  ) : (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={<FormattedMessage id="noRecord" />}
    />
  )
}

const Comments = ({
  commentStep,
  appraisalId,
  principles,
  record,
  currentStep,
  form,
  staffNo,
}) => {
  const commentTitles = principles
    ? principles.filter(item => item.step === currentStep)
    : []

  const currentCommentStep = commentStep
    ? commentStep.filter(item => item.step < currentStep)
    : []
  const { locale } = useContext(ContextStore)

  return (
    <Row gutter={24} className="comments">
      <Col span={24}>
        {currentCommentStep &&
          currentCommentStep.length > 0 &&
          currentCommentStep.map((item, index) => (
            <>
              <Divider orientation="left" key={"Divider" + index}>
                {index + 1}.{" "}
                {getPrincipleName(principles, "id", item.id, locale)}
              </Divider>
              <CommentReviewArea
                type={getPrincipleType(principles, "id", item.id)}
                id={item.commentDefinitionId}
                record={record}
              />
            </>
          ))}
        <Form form={form} preserve={false}>
          {commentTitles.length > 0 &&
            commentTitles.map((principle, index) => (
              <>
                <Divider orientation="left" key={"Divider" + index}>
                  {currentCommentStep && currentCommentStep.length > 0
                    ? currentCommentStep.length + index + 1
                    : index + 1}
                  . {translate(principle.name, locale)}
                </Divider>

                <InputArea
                  type={principle.commentType}
                  index={index}
                  history={getInputRecord(
                    record,
                    principle.commentDefinitionId,
                    staffNo,
                    true
                  )}
                  key={"InputArea" + index}
                  appraisalId={appraisalId}
                  commentId={principle.commentDefinitionId}
                />
              </>
            ))}
        </Form>
      </Col>
    </Row>
  )
}

export default Comments
