import React, { useState, useEffect } from "react"
import { Modal, Form, Select, Input } from "antd"
import { AppraisalClient } from "@api"
import { FormattedMessage } from "react-intl"
import { errorMessage } from "@action"
import { showSelectProps } from "@object"

const KPIModal = ({ visible, onCancel, id }) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState([])

  const onFinish = async values => {
    setIsLoading(true)
    const client = new AppraisalClient()
    try {
      await client.updateSiteKPI(id, {
        id: id,
        ...values,
      })
      window.location.reload()
    } catch (err) {
      errorMessage("Change KPI", err)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const getOptions = async () => {
      const client = new AppraisalClient()
      const result = await client.getAvaliableSiteKPI(id)
      setOptions(result)
    }
    if (visible) getOptions()
  }, [visible])

  return (
    <Modal
      forceRender
      width="700px"
      centered
      confirmLoading={isLoading}
      visible={visible}
      cancelText={<FormattedMessage id="cancel" />}
      okText={<FormattedMessage id="change" />}
      onCancel={onCancel}
      destroyOnClose={true}
      onOk={() => form.submit()}
      title={<FormattedMessage id="changeKPI" />}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          name="siteKPIId"
          label={<FormattedMessage id="siteKPI" />}
          rules={[
            {
              required: true,
              message: "Please select KPI. 請輸入新物業績效",
            },
          ]}
        >
          <Select
            {...showSelectProps}
            mode="multiple"
            options={options.map(option => ({
              label: option.siteOrDepartmentName,
              value: option.id,
            }))}
          />
        </Form.Item>
        <Form.Item name="remarks" label={<FormattedMessage id="remarks" />}>
          <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }} />
        </Form.Item>
      </Form>
    </Modal>
  )
}
export default KPIModal
