import React, { useContext } from "react"
import { Divider, Row, Col } from "antd"
import "./extra_score.less"
import EditableList from "./editable_list/editable_list"
import { translate } from "@action"
import { ContextStore } from "@context"

const Descriptions = ({ description }) => (
  <div dangerouslySetInnerHTML={{ __html: description }} />
)

const transformRecord = (arr, key, id) => {
  return arr.filter(item => item[key] === id)
}

const getScore = (arr, key, id) => {
  return arr.find(item => item[key] === id)
}

const ExtraScore = ({
  stepList,
  principles,
  refreshScore,
  record,
  staffNo,
  appraisalId,
  appraisee,
  currentScore,
}) => {
  let currentList = stepList.slice(1, stepList.length - 1)
  currentList = currentList.filter(item => item.isNeedUseCoreCompetencies)
  const { locale } = useContext(ContextStore)

  return (
    <Row gutter={[24, 12]} className="extra-score">
      {principles &&
        principles.length > 0 &&
        principles.map((principle, index) => (
          <>
            <Divider orientation="left" key={index}>
              {index + 1}. {translate(principle.name, locale)}
            </Divider>
            <Col span={24}>
              <Descriptions
                description={translate(principle.description, locale)}
              />
            </Col>
            <Col span={24}>
              <EditableList
                currentList={currentList}
                currentScore={getScore(
                  currentScore.scoreAdjustmentGroup,
                  "defId",
                  principle.scoreAdjustmentDefinitionId
                )}
                refreshScore={refreshScore}
                appraisee={appraisee}
                staffNo={staffNo}
                appraisalId={appraisalId}
                scoreId={principle.scoreAdjustmentDefinitionId}
                dataSource={transformRecord(
                  record,
                  "scoreAdjustmentDefinitionId",
                  principle.scoreAdjustmentDefinitionId
                )}
                principle={translate(principle.name, locale)}
              />
            </Col>
          </>
        ))}
    </Row>
  )
}

export default ExtraScore
