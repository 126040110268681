import React, { useContext, useState, useEffect } from "react"
import {
  Descriptions,
  Empty,
  Typography,
  Divider,
  Row,
  Col,
  Tag,
  Grid,
  Button,
  Space
} from "antd"
import "./appraisee_info.less"
import { translate } from "@action"
import { FormattedMessage } from "react-intl"
import { ContextStore } from "@context"
import {
  SyncOutlined,
  FileSyncOutlined,
  InteractionOutlined,
} from "@ant-design/icons"
import PrintButton from "@components/PrintButton"
import CurrentScore from "../current_score/current_score.js"
import RemarkTable from "./remark_table"
import PersonalDescription from "./personal_description"
import PathTable from "./path_table"
import { checkPermission } from "@services/auth"
import TemplateModal from "./TemplateModal"
import KPIModal from "./KPIModal"
import SiteKPITable from "./SiteKPITable"
import PastAppraisalTable from "./PastAppraisalTable"
import { TemplateType } from "@api"

const AppraiseeInfo = React.memo(
  ({
    appraisal,
    score,
    isNormal,
    degree360Weighting,
    coreCompetencyWeighting,
    siteKPIWeighting,
    templateType,
  }) => {
    const { Text } = Typography
    const id = appraisal.appraisal.id
    const [isAdmin, setIsAdmin] = useState(false)
    const [visible, setVisible] = useState(false)
    const [kpiVisible, setKpiVisible] = useState(false)
    const person = appraisal.appraisal
    const attendanceRecord = appraisal.attendanceRecord
    const appraisalNo = appraisal.appraisal.appraisalNumber
    const status = appraisal.appraisal.status
    const appraisalCycle = appraisal.appraisal.appraisalCycle
    const remark = appraisal.appraisal.appraisalHistory
    const isCanChangeKPI = appraisal.hasEdit && siteKPIWeighting > 0

    const { locale } = useContext(ContextStore)
    const { xl } = Grid.useBreakpoint()

    useEffect(() => {
      const getPermission = async () => {
        const res = await checkPermission("AppraisalCycleTable")
        setIsAdmin(res)
      }
      getPermission()
    }, [])

    return (
      <div className="appraisee-info">
        {person ? (
          <Row gutter={12}>
            <Col span={xl ? 19 : 24}>
              <Row justify="end" gutter={[12, 12]}>
                {isAdmin && (
                  <Col>
                    <Button
                      danger
                      shape="round"
                      icon={<FileSyncOutlined />}
                      onClick={() => {
                        setVisible(true)
                      }}
                    >
                      <FormattedMessage id="changeTemplate" />
                    </Button>
                  </Col>
                )}
                {![TemplateType.Degree360, TemplateType.Frontline].includes(
                  templateType
                ) && (
                  <Col>
                    <PrintButton id={id} />
                  </Col>
                )}
              </Row>
            </Col>
            <Col span={xl ? 5 : 24}>
              <Row justify="end">
                <Col>
                  <Text type="secondary">{appraisalNo}</Text>
                </Col>
              </Row>
              <Row justify="end">
                <Col>
                  <Tag style={{ margin: "0px" }} color="blue">
                    {status}
                  </Tag>
                </Col>
              </Row>
            </Col>
            <Col span={xl ? 19 : 24}>
              <PersonalDescription
                person={person}
                attendanceRecord={attendanceRecord}
                appraisalCycle={appraisalCycle}
                isNormal={isNormal}
              />

              <Divider />
              {isNormal && (
                <>
                  {siteKPIWeighting > 0 ? (
                    <>
                      <div className="ant-descriptions-title">
                        <FormattedMessage id="siteKPIHistory" />
                        {isCanChangeKPI && (
                          <Button
                            shape="round"
                            style={{ marginLeft: "10px" }}
                            icon={<InteractionOutlined />}
                            onClick={() => {
                              setKpiVisible(true)
                            }}
                          >
                            <FormattedMessage id="changeKPI" />
                          </Button>
                        )}
                      </div>
                      <SiteKPITable id={id} />
                      <Divider />
                    </>
                  ) : null}
                  <div className="ant-descriptions-title">
                    <FormattedMessage id="pastAppraisal" />
                  </div>
                  <PastAppraisalTable id={id} staffNo={person?.staffNo} />
                  <Divider />
                  <div className="ant-descriptions-title">
                    <FormattedMessage id="approvalPath" />
                  </div>
                  <PathTable id={id} />
                  <Divider />
                  <div className="ant-descriptions-title">
                    <FormattedMessage id="appraisalHistory" />
                  </div>
                  <RemarkTable remark={remark} />
                </>
              )}
            </Col>

            {xl && (
              <Col>
                <Divider
                  className="info-divider"
                  type="vertical"
                  plain
                ></Divider>
              </Col>
            )}

            {xl ? (
              <Col span={4} className="score-col">
                <Descriptions className="score-board" column={1}></Descriptions>
                <Divider orientation="left">
                  <FormattedMessage id="coreCompetencies" />
                  {" (" + coreCompetencyWeighting + "%)"}
                </Divider>
                <Descriptions className="score-board" column={1}>
                  <Descriptions.Item label={<FormattedMessage id="score" />}>
                    {score
                      ? score.coreCompetencyScore +
                        " / " +
                        score.coreCompetencyMaxScore
                      : null}
                  </Descriptions.Item>
                  {score &&
                    score.scoreAdjustmentGroup.map((item, index) => (
                      <Descriptions.Item
                        label={translate(item.name, locale)}
                        key={"score" + index}
                      >
                        {item.totalScore}
                      </Descriptions.Item>
                    ))}
                  <Descriptions.Item
                    className="sub-score"
                    label={<FormattedMessage id="subtotal" />}
                  >
                    {score?.coreCompetencySubtotal}
                  </Descriptions.Item>
                </Descriptions>
                {score &&
                (score.siteKPIMaxScore > 0 || score.isSiteKPIPending) ? (
                  <>
                    <Divider orientation="left">
                      <FormattedMessage id="siteKPI" />
                      {" (" + siteKPIWeighting + "%)"}</Divider>
                      <Space direction="vertical" size="middle">
                    <Text type="secondary"><FormattedMessage id="siteKpiDisclaimer" /></Text>
                    <Descriptions className="score-board" column={1}>
                      <Descriptions.Item
                        label={<FormattedMessage id="score" />}
                      >
                        {score.isSiteKPIPending ? (
                          <FormattedMessage id="tbc" />
                        ) : (
                          score.siteKPIScore + " / " + score.siteKPIMaxScore
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item
                        className="sub-score"
                        label={<FormattedMessage id="subtotal" />}
                      >
                        {score.siteKPISubtotal}
                      </Descriptions.Item>
                    </Descriptions>
                    </Space>
                    </>
                ) : null}
                {score && score.degree360MaxScore > 0 && (
                  <>
                    <Row justify="space-between" align="middle" wrap="false">
                      <Col>
                        <Divider orientation="left">
                          <FormattedMessage id="degree360" />
                          {" (" + degree360Weighting + "%)"}
                        </Divider>
                      </Col>
                      <Col>
                        {!score.isDegree360Completed && (
                          <Tag icon={<SyncOutlined />} color="processing">
                            <FormattedMessage id="inProgress" />
                          </Tag>
                        )}
                      </Col>
                    </Row>
                    <Descriptions className="score-board" column={1}>
                      <Descriptions.Item
                        label={<FormattedMessage id="score" />}
                      >
                        {score.degree360Score + " / " + score.degree360MaxScore}
                      </Descriptions.Item>
                      <Descriptions.Item
                        className="sub-score"
                        label={<FormattedMessage id="subtotal" />}
                      >
                        {score.degree360Subtotal}
                      </Descriptions.Item>
                    </Descriptions>
                  </>
                )}
                <Divider orientation="right">
                  <FormattedMessage id="currentScore" values={{ name: ": " }} />
                  {score?.finalScore}
                </Divider>
              </Col>
            ) : (
              <Col className="score-col">
                <CurrentScore
                  score={score}
                  isNormal={isNormal}
                  degree360Weighting={degree360Weighting}
                  coreCompetencyWeighting={coreCompetencyWeighting}
                  siteKPIWeighting={siteKPIWeighting}
                />
              </Col>
            )}
          </Row>
        ) : (
          <Empty description={<Text>console.error();</Text>}>Error</Empty>
        )}
        {isAdmin && (
          <TemplateModal
            visible={visible}
            id={id}
            templateId={appraisal.appraisal.appraisalTemplateId}
            onCancel={() => {
              setVisible(false)
            }}
          />
        )}

        {isCanChangeKPI && (
          <KPIModal
            visible={kpiVisible}
            id={id}
            onCancel={() => {
              setKpiVisible(false)
            }}
          />
        )}
      </div>
    )
  }
)

export default AppraiseeInfo
