import React, { useEffect, useState } from "react"
import { Modal, Spin, Empty, Divider, Tabs, Descriptions } from "antd"
import { AppraisalClient, GoalSettingDefinitionSetClient } from "@api"
import { FormattedMessage } from "react-intl"
import { translate } from "@action"
import moment from "moment"
import ErrorIcon from "@components/ErrorIcon"
import "./GoalModal.less"

const GoalTab = ({ id, record, locale }) => {
  const thisRecord = record.filter(item => item.goalDefinitionId === id)
  const sortedRecord =
    thisRecord.length > 0 ? thisRecord.sort((a, b) => a.step - b.step) : []
  const { TabPane } = Tabs
  return sortedRecord.length > 0 ? (
    <Tabs defaultActiveKey="key0" type="card">
      {sortedRecord.map((item, index) => (
        <TabPane
          tab={item.appraisalApprovalPathSnapshotStaffStaffName}
          key={"key" + index}
        >
          <Descriptions column={2}>
            <Descriptions.Item label={<FormattedMessage id="goals" />}>
              <pre>{item.goals}</pre>
            </Descriptions.Item>
            <Descriptions.Item label={<FormattedMessage id="plan" />}>
              <pre>{item.plan}</pre>
            </Descriptions.Item>
            <Descriptions.Item label={<FormattedMessage id="trainingPlan" />}>
              {item.trainingAndDevelopmentPlanOther
                ? item.trainingAndDevelopmentPlanOther
                : translate(item.goalSettingPlans, locale)}
            </Descriptions.Item>
            <Descriptions.Item
              label={<FormattedMessage id="expectedResults" />}
            >
              <pre>{item.expectedResults}</pre>
            </Descriptions.Item>
          </Descriptions>
        </TabPane>
      ))}
    </Tabs>
  ) : (
    ""
  )
}

const GoalReview = ({ record, principle, locale }) => {
  return principle && principle.length > 0 ? (
    principle.map(item => (
      <div>
        <Divider orientation="left">{translate(item.name, locale)}</Divider>
        <GoalTab
          id={item.goalSettingDefinitionId}
          record={record}
          locale={locale}
        />
      </div>
    ))
  ) : (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={<FormattedMessage id="noRecord" />}
    />
  )
}

const GoalModal = ({ visible, onCancel, appraisalId, locale }) => {
  const [isLoad, setIsLoad] = useState(false)
  const [record, setRecord] = useState(null)
  const [isError, setIsError] = useState(false)
  const [template, setTemplate] = useState(null)

  useEffect(() => {
    const initModal = async id => {
      let client = new AppraisalClient()
      try {
        let res = await client.getAppraisalGoalSettingDto(id)
        setRecord(res)
        if (res && res.length > 0) {
          const goalClient = new GoalSettingDefinitionSetClient()
          const goalRes = await goalClient.get2(
            res[0].appraisalAppraisalTemplateGoalSettingDefinitionSetId
          )
          setTemplate({
            ...goalRes,

            startYear: moment(
              res[0].appraisalAppraisalCycleGoalStartDate
            ).format(process.env.DATE_FORMAT),
            endYear: moment(res[0].appraisalAppraisalCycleGoalEndDate).format(
              process.env.DATE_FORMAT
            ),
          })
        }
      } catch (err) {
        console.log(err)
        setIsError(true)
      } finally {
        setIsLoad(true)
      }
    }
    if (visible) {
      initModal(appraisalId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  return (
    <Modal
      className="goal-modal"
      width={800}
      title={
        template ? (
          <FormattedMessage
            id="goalModalTitle"
            values={{
              startYear: template.startYear,
              endYear: template.endYear,
            }}
          />
        ) : (
          ""
        )
      }
      centered
      visible={visible}
      footer={null}
      destroyOnClose
      onCancel={onCancel}
    >
      {!isLoad ? (
        <Spin size="large" />
      ) : !isError ? (
        record && template && record.length > 0 ? (
          <GoalReview
            record={record}
            principle={template.goalSettingDefinitions}
            locale={locale}
          />
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<FormattedMessage id="noRecord" />}
          />
        )
      ) : (
        <ErrorIcon />
      )}
    </Modal>
  )
}

export default GoalModal
