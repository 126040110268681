import React, { useEffect, useState, useCallback } from "react"
import { Table, Space } from "antd"
import { FormattedMessage, useIntl } from "react-intl"
import { AppraisalClient } from "@api"
import { transformDate } from "@action"

const SiteKPITable = React.memo(({ id }) => {
  const [dataSource, setDataSource] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [pageMeta, setPageMeta] = useState(null)
  const { formatMessage } = useIntl()
  const columns = [
    {
      title: <FormattedMessage id="date" />,
      dataIndex: "created",
      render: (date, { isCurrent }) =>
        transformDate(date) +
        (isCurrent ? formatMessage({ id: "currentSelect" }) : ""),
    },

    {
      title: (
        <>
          <FormattedMessage id="siteKPI" /> - <FormattedMessage id="score" />
        </>
      ),
      dataIndex: "siteKPIs",
      render: siteKPIs => (
        <Space direction="vertical">
          {siteKPIs.map(siteKPI => (
            <span key={siteKPI.id}>
              {siteKPI.isSite ? (
                siteKPI.siteOrDepartment.name
              ) : (
                <FormattedMessage id="personalKPI" />
              )}
              &nbsp;-&nbsp;
              {siteKPI.isPending ? (
                <FormattedMessage id="tbc" />
              ) : (
                `${siteKPI.actualKPI}/${siteKPI.maxKPI}`
              )}
            </span>
          ))}
        </Space>
      ),
    },
    {
      title: <FormattedMessage id="staffName" />,
      dataIndex: "responsibleStaffName",
    },
    {
      title: <FormattedMessage id="remarks" />,
      dataIndex: "remarks",
    },
  ]

  const handelChange = (pagination, b, sorter, { action }) => {
    if (action === "paginate") {
      setPageNumber(pagination.current)
    }
  }

  const fetchData = useCallback(() => {
    const callAPI = async number => {
      try {
        const client = new AppraisalClient()
        const result = await client.getSiteKPIHistory(id, number, 5)
        let newList = result.items
        if (newList.length && number === 1) newList[0].isCurrent = true
        setDataSource(newList)
        setPageMeta({
          current: result.pageIndex,
          total: result.totalCount,
        })
      } catch (error) {
        console.log(error)
      }
    }
    if (!!id) callAPI(pageNumber)
  }, [pageNumber])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <Table
      rowKey="id"
      size="small"
      onChange={handelChange}
      pagination={{ ...pageMeta, pageSize: 5 }}
      columns={columns}
      dataSource={dataSource}
      scroll={{ y: 300 }}
    />
  )
})

export default SiteKPITable
