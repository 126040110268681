import React, { useEffect, useState, useContext } from "react"
import {
  Typography,
  Divider,
  Row,
  Col,
  Comment,
  Statistic,
  Empty,
  Spin,
} from "antd"
import "./degree_360.less"
import {
  AppraisalTemplateClient,
  CoreCompetencyDefinitionSetClient,
} from "@api"
import { translate, PrinciplesDescription } from "@action"
import { FormattedMessage } from "react-intl"
import { ContextStore } from "@context"

const getAvgRating = (arr, id) => {
  if (arr.length > 0) {
    let newArr = arr.filter(item => item.coreCompetencyDefinitionId === id)

    return (
      newArr.map(item => item.rating).reduce((sum, item) => sum + item) /
      newArr.length
    ).toFixed(1)
  }

  return " - "
}

const CoreReview = ({ record, principles }) => {
  const { locale } = useContext(ContextStore)
  return principles && principles.length > 0
    ? principles.map((item, index) => (
        <>
          <Col span={24} key={"name" + index}>
            <Divider key={"divider" + index} orientation="left">
              {index + 1}. {translate(item.name, locale)}
            </Divider>
          </Col>
          <Col span={12} key={"html" + index}>
            <PrinciplesDescription
              key={"descriptions" + index}
              description={translate(item.description, locale)}
            />
          </Col>
          <Col span={3} offset={9} key={"rateSession" + index}>
            <Statistic
              key={"rating" + index}
              title={<FormattedMessage id="rating" />}
              value={getAvgRating(record, item.coreCompetencyDefinitionId)}
              suffix={"/ " + item.score}
            />
          </Col>
        </>
      ))
    : null
}

const ReviewArea = ({ comments }) => {
  return comments && comments.length > 0 ? (
    comments.map((comment, index) => (
      <Comment
        key={"comment" + index}
        author={<FormattedMessage id="subordinate" />}
        content={<pre>{comment.content}</pre>}
      />
    ))
  ) : (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={<FormattedMessage id="inProgress" />}
    />
  )
}

const thisRecord = (arr, key, id) => {
  return arr.filter(item => item[key] === id)
}

const Degree360 = ({ record, score }) => {
  const { Title } = Typography
  const [template, setTemplate] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { locale } = useContext(ContextStore)
  const getTemplate = async templateId => {
    let client = new AppraisalTemplateClient()
    try {
      let res = await client.get2(templateId)

      if (!!res.coreCompetencyDefinitionSetId) {
        const coreClient = new CoreCompetencyDefinitionSetClient()
        const result = await coreClient.get2(res.coreCompetencyDefinitionSetId)
        res = {
          ...res,
          ...result,
          coreCompetencyList: result.coreCompetencyDefinitions,
        }
      }

      setTemplate(res)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getTemplate(record.appraisalTemplateId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return !isLoading ? (
    <Row gutter={24} className="degree360">
      <Col span={24}>
        <Title style={{ marginBottom: "10px" }} level={3}>
          <FormattedMessage id="coreCompetencies" />
        </Title>
      </Col>
      <CoreReview
        record={record.appraisalCoreCompetency}
        principles={template.coreCompetencyList}
      />
      <Col span={24}>
        <Divider orientation="right">
          <FormattedMessage id="score" />: {score ? score : " - "}
        </Divider>
      </Col>
      <Col span={24}>
        <Title style={{ marginBottom: "10px" }} level={3}>
          <FormattedMessage id="comments" />
        </Title>
      </Col>
      <Col span={24}>
        {template?.appraisalTemplateComment.length &&
          template.appraisalTemplateComment.map((item, index) => (
            <>
              <Divider orientation="left" key={"Divider" + index}>
                {index + 1 + ". " + translate(item.name, locale)}
              </Divider>
              <ReviewArea
                key={"degree360" + index}
                comments={thisRecord(
                  record.appraisalComment,
                  "commentDefinitionId",
                  item.commentDefinitionId
                )}
              />
            </>
          ))}
      </Col>
    </Row>
  ) : (
    <Spin size="large" />
  )
}

export default Degree360
