import React, { useState, useEffect, useContext } from "react"
import {
  Divider,
  Row,
  Col,
  Form,
  Input,
  Descriptions,
  Tabs,
  Select,
} from "antd"
import "./goalSetting.less"
import { AppraisalGoalSettingClient, GoalSettingClient } from "@api"
import {
  EditOutlined,
  LoadingOutlined,
  CheckCircleTwoTone,
} from "@ant-design/icons"
import {
  translate,
  getInputRecord,
  getOtherRecord,
  transformDate,
} from "@action"
import { FormattedMessage, useIntl } from "react-intl"
import { ContextStore } from "@context"
import { errorHandle } from "@action"
import moment from "moment"

const PrinciplesDescription = ({ description }) => (
  <div dangerouslySetInnerHTML={{ __html: description }} />
)

const ReviewSession = ({ record }) => {
  const { locale } = useContext(ContextStore)
  return (
    <Descriptions column={{ xs: 1, sm: 1, lg: 2 }}>
      <Descriptions.Item label={<FormattedMessage id="goals" />}>
        <pre>{record.goals}</pre>
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id="plan" />}>
        <pre>{record.plan}</pre>
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id="trainingPlan" />}>
        {record.trainingAndDevelopmentPlanOther
          ? record.trainingAndDevelopmentPlanOther
          : translate(record.goalSettingPlans, locale)}
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id="expectedResults" />}>
        <pre>{record.expectedResults}</pre>
      </Descriptions.Item>
    </Descriptions>
  )
}

const GoalTabs = ({
  comment,
  appraisalId,
  staffNo,
  goalId,
  index,
  setIsAping,
}) => {
  const { TabPane } = Tabs
  const otherRecords = getOtherRecord(
    comment,
    "goalDefinitionId",
    goalId,
    "",
    staffNo
  )
  const selfRecord = getInputRecord(
    comment,
    "goalDefinitionId",
    goalId,
    staffNo,
    true
  )
  const thisRecord = comment.filter(item => item.goalDefinitionId === goalId)
  const [activeKey, setActiveKey] = useState(
    otherRecords.length > 0 && !selfRecord
      ? "key" + (otherRecords.length - 1)
      : "amend"
  )
  const [isAmend, setIsAmemd] = useState(
    selfRecord || otherRecords.length < 1 ? true : false
  )

  const [newUpdateId, setNewUpdateId] = useState(
    selfRecord ? selfRecord.id : null
  )

  const handleEdit = async action => {
    setIsAmemd(!isAmend)
    try {
      if (action === "add") {
        setActiveKey("amend")
      } else {
        if (newUpdateId !== null) {
          let client = new AppraisalGoalSettingClient()
          let res = await client.delete(newUpdateId)
          if (res.isSucceeded) {
            console.log(action)
            setNewUpdateId(null)
          }
        }
        setActiveKey("key" + (otherRecords.length - 1))
      }
    } catch (err) {
      errorHandle()
    }
  }

  return (
    <Tabs
      addIcon={
        <>
          <EditOutlined />
          <FormattedMessage id="edit" />
        </>
      }
      hideAdd={isAmend}
      type={"editable-card"}
      size="small"
      activeKey={activeKey}
      onChange={activeKey => {
        setActiveKey(activeKey)
      }}
      onEdit={(_, action) => handleEdit(action)}
    >
      {otherRecords.length > 0 &&
        otherRecords.map((record, index) => (
          <TabPane tab={record.staff.name} closable={false} key={"key" + index}>
            <ReviewSession record={record} />
          </TabPane>
        ))}

      {isAmend && (
        <TabPane tab={<FormattedMessage id="you" />} key="amend">
          <InputArea
            setIsAping={setIsAping}
            history={
              selfRecord
                ? selfRecord
                : {
                    goals: thisRecord[thisRecord.length - 1].goals,
                    plan: thisRecord[thisRecord.length - 1].plan,
                    expectedResults:
                      thisRecord[thisRecord.length - 1].expectedResults,
                    trainingAndDevelopmentPlanOther:
                      thisRecord[thisRecord.length - 1]
                        .trainingAndDevelopmentPlanOther,
                    goalSettingPlansId:
                      thisRecord[thisRecord.length - 1].goalSettingPlansId,
                    id: null,
                  }
            }
            appraisalId={appraisalId}
            goalId={goalId}
            setNewUpdateId={setNewUpdateId}
            newUpdateId={newUpdateId}
            isAmend
            index={index}
          />
        </TabPane>
      )}
    </Tabs>
  )
}

const GoalReview = ({
  period,
  review,
  appraisalId,
  staffNo,
  principles,
  setIsAping,
}) => {
  const { locale } = useContext(ContextStore)

  return (
    <>
      <Divider orientation="left">
        <FormattedMessage id="goalPeriod" />
        {transformDate(period.goalStartDate) +
          " to " +
          transformDate(period.goalEndDate)}
      </Divider>
      {principles.map((principle, index) => (
        <>
          <Divider orientation="left" key={"GoalDivider" + index}>
            {index + 1}. {translate(principle.name, locale)}
          </Divider>
          <div className="goal-form">
            <GoalTabs
              setIsAping={setIsAping}
              comment={review}
              appraisalId={appraisalId}
              staffNo={staffNo}
              goalId={principle.goalSettingDefinitionId}
              index={index}
            />
          </div>
        </>
      ))}
    </>
  )
}

const InputArea = ({
  appraisalId,
  index,
  goalId,
  history,
  setNewUpdateId,
  newUpdateId,
  isAmend,
  setIsAping,
}) => {
  const { locale } = useContext(ContextStore)
  const [planOptions, setPlanOptions] = useState([])
  const { Option } = Select
  const { TextArea } = Input
  const [isTouch, setIsTouch] = useState(false)
  const [goals, setGoals] = useState(history ? history.goals : null)
  const [plan, setPlan] = useState(history ? history.plan : null)
  const [saveTime, setSaveTime] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [trainingPlan, setTrainingPlan] = useState(
    history
      ? history.goalSettingPlansId === 0
        ? -1
        : history.goalSettingPlansId
      : null
  )
  const [prevPlan, setPrevPlan] = useState(trainingPlan)
  const [otherPlan, setOtherPlan] = useState(
    history ? history.trainingAndDevelopmentPlanOther : null
  )
  const [results, setResults] = useState(
    history ? history.expectedResults : null
  )
  const [updateId, setUpdateId] = useState(history ? history.id : null)
  const { formatMessage } = useIntl()
  const placeHolder = formatMessage({ id: "writeAppriopriate" })
  const resultplaceHolder = formatMessage({ id: "resultExample" })
  const inputWarning = formatMessage({ id: "pleaseInput" }, { name: "" })
  const tranPlaceholder = formatMessage(
    { id: "pleaseInput" },
    { name: formatMessage({ id: "trainingPlan" }) }
  )
  const selectPlaceHolder = formatMessage(
    { id: "pleaseSelect" },
    { name: formatMessage({ id: "plan" }) }
  )

  const other = formatMessage({ id: "other" })

  const props = { xs: 24, lg: 11 }

  const handleSubmit = async () => {
    setIsSubmitting(true)
    setIsAping(true)
    let client = new AppraisalGoalSettingClient()
    let res
    try {
      if (!isAmend ? updateId === null : newUpdateId === null) {
        console.log("submit Data")
        res = await client.create({
          goals: goals,
          plan: plan,
          expectedResults: results,
          goalSettingPlansId: trainingPlan === -1 ? null : trainingPlan,
          trainingAndDevelopmentPlanOther:
            trainingPlan === -1 ? otherPlan : null,
          appraisalId: appraisalId,
          goalSettingDefinitionId: goalId,
        })
        if (!isAmend) {
          setUpdateId(res.content)
        } else {
          setNewUpdateId(res.content)
        }
      } else {
        console.log("upadate")
        res = await client.update({
          goals: goals,
          plan: plan,
          expectedResults: results,
          goalSettingPlansId: trainingPlan === -1 ? null : trainingPlan,
          trainingAndDevelopmentPlanOther:
            trainingPlan === -1 ? otherPlan : null,
          id: !isAmend ? updateId : newUpdateId,
        })
      }
    } catch (err) {
      errorHandle()
    } finally {
      const obj = {
        date: moment().format("YYYY-MM-DD"),
        time: moment().format("HH:mm:ss"),
      }
      setSaveTime(obj)
      setIsSubmitting(false)
      setIsAping(false)
    }
  }

  useEffect(() => {
    let client = new GoalSettingClient()
    client
      .get2(goalId)
      .then(result => {
        setPlanOptions(result.content.goalSettingPlansGroup)
      })
      .catch(err => {
        console.log(err)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isTouch && (goals || !!trainingPlan || !!plan || !!results)) {
      handleSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goals, plan, trainingPlan, otherPlan, results])

  return (
    <Row justify="space-between">
      <Col {...props}>
        <Form.Item
          name={"goals" + index}
          label={<FormattedMessage id="goals" />}
          onBlur={e => {
            setIsTouch(true)
            setGoals(e.target.value)
          }}
          rules={[{ required: true, message: inputWarning }]}
          initialValue={goals}
        >
          <TextArea
            disabled={isSubmitting}
            autoSize={{ minRows: 5, maxRows: 5 }}
            placeholder={placeHolder}
          />
        </Form.Item>
      </Col>
      <Col {...props}>
        <Form.Item
          name={"plan" + index}
          label={<FormattedMessage id="plan" />}
          onBlur={e => {
            setIsTouch(true)
            setPlan(e.target.value)
          }}
          rules={[{ required: true, message: inputWarning }]}
          initialValue={plan}
        >
          <TextArea
            disabled={isSubmitting}
            autoSize={{ minRows: 5, maxRows: 5 }}
            placeholder={placeHolder}
          />
        </Form.Item>
      </Col>
      <Col {...props}>
        <Form.Item
          name={"trainingAndDevelopmentPlan" + index}
          label={<FormattedMessage id="trainingPlan" />}
          initialValue={trainingPlan}
          rules={[{ required: true, message: inputWarning }]}
        >
          <Select
            disabled={isSubmitting}
            onSelect={value => {
              setIsTouch(true)
              setTrainingPlan(value)
              setPrevPlan(trainingPlan)
            }}
            placeholder={selectPlaceHolder}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.value === -1
            }
            showSearch
          >
            {planOptions.length > 0 &&
              planOptions.map(item => (
                <Option key={item.id} value={item.id}>
                  {translate(item.name, locale)}
                </Option>
              ))}
            <Option value={-1}>{other}</Option>
          </Select>
        </Form.Item>
        <Form.Item noStyle shouldUpdate={prevPlan !== trainingPlan}>
          {trainingPlan === -1 && (
            <Form.Item
              name={"other" + index}
              rules={[{ required: true, message: inputWarning }]}
              onBlur={e => {
                setIsTouch(true)
                setOtherPlan(e.target.value)
              }}
              initialValue={otherPlan}
            >
              <Input disabled={isSubmitting} placeholder={tranPlaceholder} />
            </Form.Item>
          )}
        </Form.Item>
      </Col>
      <Col {...props}>
        <Form.Item
          name={"expectedResults" + index}
          label={<FormattedMessage id="expectedResults" />}
          rules={[{ required: true, message: inputWarning }]}
          onBlur={e => {
            setIsTouch(true)
            setResults(e.target.value)
          }}
          initialValue={results}
        >
          <TextArea
            disabled={isSubmitting}
            autoSize={{ minRows: 5, maxRows: 5 }}
            placeholder={resultplaceHolder}
          />
        </Form.Item>
      </Col>
      <Col span={10} offset={14}>
        <Row justify="end">
          {isSubmitting ? (
            <LoadingOutlined spin />
          ) : (
            <>
              <Col>
                {" "}
                {saveTime ? (
                  <FormattedMessage id="lastSavedOn" values={saveTime} />
                ) : null}
              </Col>
              <Col offset={1}>
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              </Col>
            </>
          )}
        </Row>
      </Col>
    </Row>
  )
}

const GoalForm = ({
  period,
  principles,
  guideLine,
  staffNo,
  record,
  appraisalId,
  setIsAping,
}) => {
  const { locale } = useContext(ContextStore)
  return (
    <>
      <Divider orientation="left">
        <FormattedMessage id="goalPeriod" />
        {transformDate(period.goalStartDate) +
          " to " +
          transformDate(period.goalEndDate)}
      </Divider>

      <PrinciplesDescription description={translate(guideLine, locale)} />

      {principles &&
        principles.length > 0 &&
        principles.map((principle, index) => (
          <div className="goal-form">
            <Divider orientation="left" key={index}>
              {index + 1}. {translate(principle.name, locale)}
            </Divider>

            <Col span={24}>
              <InputArea
                setIsAping={setIsAping}
                history={getInputRecord(
                  record,
                  "goalDefinitionId",
                  principle.goalSettingDefinitionId,
                  staffNo,
                  true
                )}
                appraisalId={appraisalId}
                goalId={principle.goalSettingDefinitionId}
                key={"InputArea" + index}
                index={index}
              />
            </Col>
          </div>
        ))}
    </>
  )
}

const GoalSetting = ({
  form,
  principles,
  staffNo,
  appraisalId,
  record,
  guideLine,
  period,
  setIsAping,
}) => {
  const review = record
    ? record.length > 0 && record[0].staff.staffNo !== staffNo
    : false
  return (
    <Row className="goalSetting" gutter={24}>
      <Col span={24}>
        <Form form={form} layout="vertical" preserve={false}>
          {review ? (
            <GoalReview
              setIsAping={setIsAping}
              period={period}
              appraisalId={appraisalId}
              staffNo={staffNo}
              principles={principles}
              review={record}
            />
          ) : (
            <GoalForm
              setIsAping={setIsAping}
              period={period}
              principles={principles}
              guideLine={guideLine}
              staffNo={staffNo}
              record={record}
              appraisalId={appraisalId}
            />
          )}
        </Form>
      </Col>
    </Row>
  )
}

export default GoalSetting
