import React from "react"
import { Empty } from "antd"
import { FormattedMessage } from "react-intl"

const NoAmendEmpty = () => (
  <Empty
    image={Empty.PRESENTED_IMAGE_SIMPLE}
    description={<FormattedMessage id="noAdjust" />}
    style={{ width: "fit-content", margin: "0px" }}
  />
)

export default NoAmendEmpty
