import React from "react"
import { Space } from "antd"
import { FormattedMessage } from "react-intl"
import { ExclamationCircleTwoTone } from "@ant-design/icons"
const ErrorIcon = () => {
  return (
    <Space direction="vertical" align="center" style={{ width: "100%" }}>
      <ExclamationCircleTwoTone
        twoToneColor="red"
        style={{ fontSize: "5rem" }}
      />
      <FormattedMessage id="error" />
    </Space>
  )
}

export default ErrorIcon
