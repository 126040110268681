import React, { useEffect, useState, useContext } from "react"
import {
  Input,
  Popconfirm,
  Form,
  Button,
  Upload,
  Select,
  Typography,
  Row,
  Col,
  Descriptions,
  Tabs,
  Divider,
  message,
  Grid,
} from "antd"
import {
  UploadOutlined,
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  CloseOutlined,
  SaveOutlined,
  DownloadOutlined,
  LoadingOutlined,
  CheckCircleTwoTone,
} from "@ant-design/icons"
import "./editable_list.less"
import { ScoreAdjustmentClient, AppraisalScoreAdjustmentClient } from "@api"
import { translate, tranformFile, getNowDate } from "@action"
import NoAmendEmpty from "@components/NoAmendEmpty"
import { FormattedMessage, useIntl } from "react-intl"
import { ContextStore } from "@context"
import { errorHandle } from "@action"

//TODO : the amend record shd be null

const handleDownload = async file => {
  let client = new AppraisalScoreAdjustmentClient()
  try {
    let res = await client.attachment(file.uid)
    let url = window.URL.createObjectURL(res.data)
    let a = document.createElement("a")
    a.href = url
    a.download = res.fileName
    a.click()
    a.remove()
  } catch (err) {
    errorHandle()
  }
}

const transformSourceData = data => {
  let newData = data.filter(item => item.title !== null)
  return newData.map((item, index) => ({ ...item, item: index }))
}

const InputArea = ({
  refreshScore,
  appraisalId,
  principle,
  history,
  setNewUpdateId,
  newUpdateId,
  option,
  scoreAdjustmentNumber,
  scoreId,
}) => {
  const { locale } = useContext(ContextStore)
  const { formatMessage } = useIntl()
  const { TextArea } = Input
  const { Option } = Select
  const { Text } = Typography
  const [isTouch, setIsTouch] = useState(false)
  const [saveTime, setSaveTime] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [description, setDescription] = useState(
    history ? history.description : null
  )
  const [ratingId, setRatingId] = useState(history ? history.rating : null)
  const [fileList, setFileList] = useState(
    history
      ? tranformFile(
          history.appraisalScoreAdjustmentFile,
          "AppraisalScoreAdjustment"
        )
      : []
  )

  const [oldFileList, setOldFileList] = useState(
    history
      ? tranformFile(
          history.appraisalScoreAdjustmentFile,
          "AppraisalScoreAdjustment"
        )
      : []
  )

  const handleSubmit = async () => {
    if (!!ratingId || ratingId === 0) {
      setIsSubmitting(true)
      let client = new AppraisalScoreAdjustmentClient()
      let result
      let files =
        fileList.length > 0
          ? fileList.map(item => {
              return { data: item.originFileObj, fileName: item.name }
            })
          : null
      try {
        if (newUpdateId === null) {
          console.log("submitData")
          result = await client.create(
            scoreAdjustmentNumber,
            null,
            description,
            ratingId === 0 ? null : ratingId,
            scoreId,
            appraisalId,
            null,
            files
          )
          setNewUpdateId(result.content.id)
        } else {
          console.log("updateData")
          let newList = []
          fileList.forEach(item => {
            if ("url" in item) {
              newList.push(item)
            }
          })

          let removedList = []

          oldFileList.forEach(oldItem => {
            if (
              newList.findIndex(newItem => newItem.uid === oldItem.uid) === -1
            )
              removedList.push(oldItem.uid)
          })

          let newUploadList = []
          fileList.forEach(item => {
            if ("size" in item) {
              newUploadList.push({
                data: item.originFileObj,
                fileName: item.name,
              })
            }
          })

          result = await client.update(
            newUpdateId,
            null,
            description,
            null,
            ratingId === 0 ? null : ratingId,
            newUploadList,
            removedList
          )
        }
        setIsTouch(false)
        refreshScore()
        setFileList(
          tranformFile(
            result.content.appraisalScoreAdjustmentFile,
            "AppraisalScoreAdjustment"
          )
        )
        setOldFileList(
          tranformFile(
            result.content.appraisalScoreAdjustmentFile,
            "AppraisalScoreAdjustment"
          )
        )
      } catch (err) {
        errorHandle()
      } finally {
        let now = new Date()
        setSaveTime(getNowDate(now))
        setIsSubmitting(false)
      }
    }
  }

  const onChange = ({ file: newFile, fileList: newFileList }) => {
    const isValid =
      newFile.type === "image/png" ||
      newFile.type === "image/gif" ||
      newFile.type === " image/jpg" ||
      newFile.type === "image/jpeg" ||
      newFile.type === "application/pdf" ||
      newFile.type === "text/plain"
    const isLt5M = newFile.size / 1024 / 1024 < 5
    if (
      (newFile.status && newFile.status === "removed") ||
      (isLt5M && isValid)
    ) {
      setIsTouch(true)
      setFileList([...newFileList])
    } else if (!isValid) {
      message.error("The file type of " + newFile.name + " is not accepted")
    } else if (!isLt5M) {
      message.error(newFile.name + " cannot be larger than 5 Mbs")
    }
  }

  const textAreaHolder = formatMessage({ id: "textAreaReviewComment" })

  useEffect(() => {
    if (isTouch) {
      handleSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description, ratingId, fileList])

  return (
    <Row align="middle">
      <Col span={18}>
        <Form.Item
          preserve={false}
          name={principle + scoreAdjustmentNumber + "desciption"}
          onBlur={e => {
            setIsTouch(true)
            setDescription(e.target.value)
          }}
          initialValue={description}
        >
          <TextArea
            disabled={isSubmitting}
            autoSize={{ minRows: 5, maxRows: 5 }}
            placeholder={textAreaHolder}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          preserve={false}
          name={principle + scoreAdjustmentNumber + "rating"}
          label={<FormattedMessage id="score" />}
          className="select-div"
          initialValue={ratingId}
        >
          <Select
            disabled={isSubmitting}
            onSelect={value => {
              setIsTouch(true)
              setRatingId(value)
            }}
            placeholder={principle === "Select the mark"}
          >
            <Option value={0}>
              <FormattedMessage id="invalid" />
            </Option>

            {option &&
              option.map(item => (
                <Option value={item.id}>{translate(item.name, locale)}</Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={14}>
        <Form.Item
          preserve={false}
          extra={
            <Text type="secondary">
              <FormattedMessage id="maxFileSize" values={{ name: "5MB" }} />
              <br />
              <FormattedMessage
                id="fileType"
                values={{ name: "png gif jpg jpeg pdf txt" }}
              />
            </Text>
          }
        >
          <Upload
            size="small"
            beforeUpload={() => false}
            onPreview={handleDownload}
            onChange={onChange}
            fileList={fileList}
            accept=".png,  .gif, .jpg, .jpeg, .pdf, .txt"
          >
            {fileList.length < 3 && (
              <Button disabled={isSubmitting} icon={<UploadOutlined />}>
                {
                  <FormattedMessage
                    id="upload"
                    values={{ name: <FormattedMessage id="attachment" /> }}
                  />
                }
              </Button>
            )}
          </Upload>
        </Form.Item>
      </Col>
      <Col span={10}>
        <Row gutter={[10, 10]} justify="end">
          {isSubmitting ? (
            <LoadingOutlined spin />
          ) : (
            <>
              <Col>{saveTime}</Col>
              <Col>
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              </Col>
            </>
          )}
        </Row>
      </Col>
    </Row>
  )
}

const EditableForm = ({
  isSubmitting,
  option,
  list,
  editable,
  save,
  edit,
  handleDelete,
  cancel,
  editingKey,
  staffNo,
}) => {
  const { Option } = Select
  const { TextArea } = Input
  const { Text } = Typography
  const isSelfRecord = list.staff ? list.staff.staffNo === staffNo : true
  const { locale } = useContext(ContextStore)
  const { xl } = Grid.useBreakpoint()
  const normFile = e => {
    return e.fileList
  }
  const { formatMessage } = useIntl()

  const titleWarning = formatMessage(
    { id: "pleaseInput" },
    { name: formatMessage({ id: "title" }) }
  )
  const scoreWarning = formatMessage(
    { id: "pleaseSelect" },
    { name: formatMessage({ id: "score" }) }
  )

  const scorePlaceholder = formatMessage(
    { id: "selectPlaceholder" },
    { name: formatMessage({ id: "score" }) }
  )

  const filterFile = (value, prevValue) => {
    let isValid
    let isLt5M
    let name
    let hvUrl
    if (value && value.length > 0) {
      value.every(item => {
        isValid =
          item.type === "image/png" ||
          item.type === "image/gif" ||
          item.type === " image/jpg" ||
          item.type === "image/jpeg" ||
          item.type === "application/pdf" ||
          item.type === "text/plain"
        isLt5M = item.size / 1024 / 1024 < 5
        name = item.name
        hvUrl = "url" in item
        return hvUrl || (isLt5M && isValid)
      })

      if (!isValid && !hvUrl) {
        message.error("The file type of " + name + " is not accepted")
        return prevValue
      } else if (!isLt5M && !hvUrl) {
        message.error("File cannot be larger than 5 Mbs")
        return prevValue
      }
    }
    return value
  }

  return (
    <>
      <Row gutter={[24, 30]} align="middle">
        {editable ? (
          <>
            <Col span={!isSelfRecord || !xl ? 24 : 20}>
              <Row align="top" justify="space-between">
                <Col span={2}>
                  <Form.Item label={<FormattedMessage id="item" />}>
                    {list.item + 1}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    rules={[{ required: true, message: titleWarning }]}
                    name="title"
                    label={<FormattedMessage id="title" />}
                    initialValue={list.title}
                    wrapperCol={{ span: 23 }}
                  >
                    <Input disabled={isSubmitting} />
                  </Form.Item>
                </Col>
                <Col xs={16} xl={7}>
                  <Form.Item
                    name="description"
                    label={<FormattedMessage id="description" />}
                    initialValue={list.description}
                    wrapperCol={{ span: 23 }}
                  >
                    <TextArea disabled={isSubmitting} />
                  </Form.Item>
                </Col>
                <Col xs={6} xl={5} offset={xl ? 0 : 2}>
                  <Form.Item
                    label={<FormattedMessage id="attachment" />}
                    name="files"
                    getValueFromEvent={normFile}
                    normalize={filterFile}
                    valuePropName="fileList"
                    initialValue={tranformFile(
                      list.appraisalScoreAdjustmentFile,
                      "AppraisalScoreAdjustment"
                    )}
                    extra={
                      <Text type="secondary">
                        <FormattedMessage
                          id="maxFileSize"
                          values={{ name: "5MB" }}
                        />
                        <br />
                        <FormattedMessage
                          id="fileType"
                          values={{ name: "png gif jpg jpeg pdf txt" }}
                        />
                      </Text>
                    }
                  >
                    <Upload
                      size="small"
                      beforeUpload={() => false}
                      accept=".png,  .gif, .jpg, .jpeg, .pdf, .txt"
                    >
                      <Button disabled={isSubmitting} icon={<UploadOutlined />}>
                        {<FormattedMessage id="upload" values={{ name: "" }} />}
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
                <Col xs={16} xl={4}>
                  {option.length > 1 ? (
                    <Form.Item
                      name="scoreAdjustmentOptionId"
                      label={<FormattedMessage id="score" />}
                      rules={[{ required: true, message: scoreWarning }]}
                      initialValue={
                        list.scoreAdjustmentOption
                          ? list.scoreAdjustmentOption.id
                          : null
                      }
                    >
                      <Select
                        disabled={isSubmitting}
                        placeholder={scorePlaceholder}
                      >
                        {option.map(item => (
                          <Option key={item.id} value={item.id}>
                            {translate(item.name, locale)}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  ) : (
                    <Form.Item
                      name="scoreAdjustmentOptionId"
                      label={<FormattedMessage id="score" />}
                      initialValue={option[0].id}
                    >
                      {option[0].score}
                    </Form.Item>
                  )}
                </Col>
              </Row>
            </Col>
            {isSelfRecord && (
              <>
                <Col xs={24} xl={4}>
                  <Button
                    htmlType="submit"
                    icon={<SaveOutlined />}
                    shape="round"
                    size="small"
                    onClick={save}
                    style={{
                      marginRight: 8,
                    }}
                    loading={isSubmitting}
                  >
                    <FormattedMessage id="save" />
                  </Button>
                  <Popconfirm
                    title={
                      <FormattedMessage
                        id="sureTo"
                        values={{ name: <FormattedMessage id="close" /> }}
                      />
                    }
                    onConfirm={cancel}
                    okText={<FormattedMessage id="ok" />}
                    cancelText={<FormattedMessage id="cancel" />}
                  >
                    <Button icon={<CloseOutlined />} shape="round" size="small">
                      <FormattedMessage id="close" />
                    </Button>
                  </Popconfirm>
                </Col>
              </>
            )}
          </>
        ) : (
          <>
            <Col span={!isSelfRecord || !xl ? 24 : 20}>
              <Descriptions
                size="small"
                column={24}
                layout="vertical"
                colon={false}
                className="list-descriptions"
              >
                <Descriptions.Item
                  span={2}
                  label={<FormattedMessage id="item" />}
                >
                  {list.item + 1}
                </Descriptions.Item>
                <Descriptions.Item
                  span={6}
                  label={<FormattedMessage id="title" />}
                >
                  {list.title}
                </Descriptions.Item>
                <Descriptions.Item
                  span={xl ? 7 : 16}
                  label={<FormattedMessage id="description" />}
                >
                  <pre>{list.description ?? " - "}</pre>
                </Descriptions.Item>
                <Descriptions.Item
                  span={xl ? 6 : 8}
                  label={<FormattedMessage id="attachment" />}
                >
                  <Upload
                    onPreview={handleDownload}
                    fileList={tranformFile(
                      list.appraisalScoreAdjustmentFile,
                      "AppraisalScoreAdjustment"
                    )}
                    showUploadList={{ showRemoveIcon: false }}
                  />
                </Descriptions.Item>
                <Descriptions.Item
                  span={xl ? 3 : 16}
                  label={<FormattedMessage id="score" />}
                >
                  {list.scoreAdjustmentOption?.score}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            {isSelfRecord && (
              <>
                <Col xs={24} xl={4}>
                  <Button
                    icon={<EditOutlined />}
                    shape="round"
                    size="small"
                    disabled={editingKey !== ""}
                    onClick={edit}
                    style={{
                      marginRight: 8,
                    }}
                  >
                    <FormattedMessage id="edit" />
                  </Button>
                  <Popconfirm
                    title={
                      <FormattedMessage
                        id="sureTo"
                        values={{ name: <FormattedMessage id="delete" /> }}
                      />
                    }
                    okText={<FormattedMessage id="ok" />}
                    cancelText={<FormattedMessage id="cancel" />}
                    onConfirm={handleDelete}
                  >
                    <Button
                      icon={<DeleteOutlined />}
                      shape="round"
                      size="small"
                      disabled={editingKey !== ""}
                    >
                      <FormattedMessage id="delete" />
                    </Button>
                  </Popconfirm>
                </Col>
              </>
            )}
          </>
        )}
      </Row>
    </>
  )
}

const getShowRecord = (arr, currentList) => {
  let showRecord = []
  let lastIndex = 0
  currentList.forEach(item => {
    item.approver.forEach(approver => {
      let index = arr.findIndex(
        record => record.staff.staffNo === approver.staffNo
      )
      if (index !== -1) {
        showRecord.push({ ...arr[index], isAmend: true })
        lastIndex = index
      } else {
        showRecord.push({
          isAmend: false,
          scoreAdjustmentOption: arr[lastIndex].scoreAdjustmentOption,
          staff: {
            name: approver.name,
          },
        })
      }
    })
  })
  return showRecord
}

const ReviewList = ({
  currentList,
  refreshScore,
  reviews,
  listIndex,
  scoreAdjustmentNumber,
  staffNo,
  principle,
  lastScore,
  option,
  scoreId,
  appraisalId,
}) => {
  const { TabPane } = Tabs
  const reviewRecord = getShowRecord(reviews, currentList)
  const selfRecord = reviews.find(item => item.staff.staffNo === staffNo)
  const { xl } = Grid.useBreakpoint()

  const [isAmend, setIsAmemd] = useState(selfRecord ? true : false)

  const [newUpdateId, setNewUpdateId] = useState(
    selfRecord ? selfRecord.id : null
  )

  const [activeKey, setActiveKey] = useState(
    reviewRecord.length > 0 && !selfRecord
      ? "key" + (reviewRecord.length - 1)
      : "amend"
  )
  const handleEdit = async (_, action) => {
    setIsAmemd(!isAmend)
    try {
      if (action === "add") {
        setActiveKey("amend")
      } else {
        if (newUpdateId !== null) {
          let client = new AppraisalScoreAdjustmentClient()
          let res = await client.delete(newUpdateId)
          if (res.isSucceeded) {
            setNewUpdateId(null)
            refreshScore()
          }
        }
        setActiveKey("key" + (reviewRecord.length - 1))
      }
    } catch (err) {
      errorHandle()
    }
  }

  return (
    <>
      {reviewRecord && reviewRecord.length < 1 && !isAmend && (
        <Button
          className="edit-button"
          type="primary"
          shape="round"
          icon={<EditOutlined />}
          onClick={() => handleEdit("", "add")}
        >
          <FormattedMessage id="edit" />
        </Button>
      )}
      <Tabs
        addIcon={
          <>
            <EditOutlined />
            <FormattedMessage id="edit" />
          </>
        }
        hideAdd={reviewRecord && reviewRecord.length > 0 ? isAmend : true}
        type={"editable-card"}
        size="small"
        activeKey={activeKey}
        onChange={activeKey => {
          setActiveKey(activeKey)
        }}
        onEdit={(_, action) => handleEdit(_, action)}
      >
        {!!reviewRecord &&
          reviewRecord.length > 0 &&
          reviewRecord.map((review, index) => (
            <TabPane
              tab={review.staff.name}
              key={"key" + index}
              closable={false}
            >
              <Descriptions
                size="small"
                column={24}
                layout="vertical"
                colon={false}
              >
                {review.isAmend ? (
                  <>
                    <Descriptions.Item span={xl ? 7 : 24} label="Comment">
                      <pre>
                        {review.description ? review.description : " - "}
                      </pre>
                    </Descriptions.Item>
                    <Descriptions.Item span={xl ? 6 : 21} label="Attachment">
                      <Upload
                        showUploadList={{
                          showDownloadIcon: true,
                          downloadIcon: <DownloadOutlined />,
                          showRemoveIcon: false,
                        }}
                        onPreview={handleDownload}
                        fileList={tranformFile(
                          review.appraisalScoreAdjustmentFile,
                          "AppraisalCoreCompetency"
                        )}
                      ></Upload>
                    </Descriptions.Item>
                  </>
                ) : (
                  <Descriptions.Item span={21}>
                    <NoAmendEmpty />
                  </Descriptions.Item>
                )}

                <Descriptions.Item
                  span={3}
                  label={<FormattedMessage id="score" />}
                >
                  {review.scoreAdjustmentOption ? (
                    review.scoreAdjustmentOption.score
                  ) : (
                    <FormattedMessage id="invalid" />
                  )}
                </Descriptions.Item>
              </Descriptions>
            </TabPane>
          ))}
        {isAmend && (
          <TabPane tab={<FormattedMessage id="you" />} key="amend">
            <Form>
              <InputArea
                refreshScore={refreshScore}
                history={
                  selfRecord && !!newUpdateId
                    ? {
                        ...selfRecord,
                        rating: selfRecord.scoreAdjustmentOption
                          ? selfRecord.scoreAdjustmentOption.id
                          : 0,
                      }
                    : {
                        description: null,
                        rating:
                          !reviewRecord.length > 0
                            ? lastScore
                            : reviewRecord[reviewRecord.length - 1]
                                .scoreAdjustmentOption
                            ? reviewRecord[reviewRecord.length - 1]
                                .scoreAdjustmentOption.id
                            : 0,
                      }
                }
                appraisalId={appraisalId}
                scoreAdjustmentNumber={scoreAdjustmentNumber}
                newUpdateId={newUpdateId}
                setNewUpdateId={setNewUpdateId}
                principle={principle}
                index={listIndex}
                option={option}
                scoreId={scoreId}
              />
            </Form>
          </TabPane>
        )}
      </Tabs>
    </>
  )
}
const EditableList = ({
  scoreId,
  dataSource,
  principle,
  previewOnly,
  staffNo,
  appraisalId,
  refreshScore,
  currentScore,
  currentList,
}) => {
  const [form] = Form.useForm()
  const [scoreContent, setScoreContent] = useState(null)
  const [data, setData] = useState(
    dataSource ? transformSourceData(dataSource) : []
  )
  const [score, setScore] = useState(dataSource.score ? dataSource.score : 0)
  const [editingKey, setEditingKey] = useState("")
  const isEditing = record => record.id === editingKey
  const { TabPane } = Tabs
  const [isSubmitting, setIsSubmitting] = useState(false)

  const add = () => {
    const newData = {
      id: -1,
      item: data.length,
      staff: { staffNo: staffNo },
      scoreAdjustmentNumber: 0,
    }
    setData([...data, newData])
    setEditingKey(newData.id)
  }

  const handleSubmit = async (id, comment) => {
    console.log(comment)
    setIsSubmitting(true)
    let client = new AppraisalScoreAdjustmentClient()
    let result
    let newId =
      Math.max.apply(
        Math,
        data.map(item => item.scoreAdjustmentNumber)
      ) + 1
    let files =
      !!comment.files && comment.files.length > 0
        ? comment.files.map(item => {
            return { data: item.originFileObj, fileName: item.name }
          })
        : null

    try {
      if (id === -1) {
        result = await client.create(
          newId,
          comment.title,
          comment.description,
          comment.scoreAdjustmentOptionId,
          scoreId,
          appraisalId,
          null,
          files
        )
      } else {
        let index = data.findIndex(item => id === item.id)
        let oldFileList = data[index].appraisalScoreAdjustmentFile
        let newList = []
        comment.files.forEach(item => {
          if ("url" in item) {
            newList.push(item)
          }
        })

        let removedList = []

        oldFileList.forEach(oldItem => {
          if (newList.findIndex(newItem => newItem.uid === oldItem.id) === -1)
            removedList.push(oldItem.id)
        })

        let newUploadList = []
        comment.files.forEach(item => {
          if ("size" in item) {
            newUploadList.push({
              data: item.originFileObj,
              fileName: item.name,
            })
          }
        })
        result = await client.update(
          id,
          comment.title,
          comment.description,
          null,
          comment.scoreAdjustmentOptionId,
          newUploadList,
          removedList
        )
      }
      let newData = [...data]
      let index = newData.findIndex(item => id === item.id)

      let oldItem = newData[index]
      let item = result.content
      if (id === -1) {
        item.scoreAdjustmentNumber = newId
      }
      newData.splice(index, 1, {
        ...item,
        item: oldItem.item,
      })
      setData(newData)
      refreshScore()
      setEditingKey("")
    } catch (err) {
      errorHandle()
    } finally {
      setIsSubmitting(false)
    }
  }

  const save = async id => {
    try {
      const row = await form.validateFields()

      handleSubmit(id, { ...row })
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo)
    }
  }

  const handleDelete = async record => {
    setScore(score - record.mark)
    //TODO: Delete function error
    let canDelete = record.id === -1
    if (record.id !== -1) {
      try {
        let client = new AppraisalScoreAdjustmentClient()
        await client.delete(record.id)

        message.success("Delete item success")
        canDelete = true
      } catch (err) {
        errorHandle()
      }
    }

    if (canDelete) {
      setData(data.filter(item => item.id !== record.id))
    }
    await refreshScore()
  }

  const edit = record => {
    form.setFieldsValue({
      title: record.title ? record.title : "",
      description: record.description ? record.description : "",
      mark: record.mark ? record.mark : "",
      ...record,
    })
    setEditingKey(record.id)
  }

  const cancel = record => {
    if (record.id === -1) {
      handleDelete(record)
    }
    setEditingKey("")
  }

  useEffect(() => {
    let client = new ScoreAdjustmentClient()
    client
      .get2(scoreId)
      .then(result => {
        setScoreContent(result.content)
      })
      .catch(err => {
        console.log(err)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const canAdd = (arr, staffNo, maxItem) => {
    return arr.filter(item => item.staff.staffNo === staffNo).length >= maxItem
  }

  const isSelfRecord = (staffNo, selfNo) => {
    return staffNo === selfNo
  }

  const thisRecord = (arr, scoreAdjustmentNumber, id) => {
    return arr.filter(
      item => item.scoreAdjustmentNumber === scoreAdjustmentNumber
    )
  }

  const cutOffCurrent = (currentList, record) => {
    let newList = [...currentList]

    const newIndex = newList.findIndex(item => item.step === record.step)

    return newList.splice(newIndex + 1)
  }

  return (
    <div className="editable-list">
      {!previewOnly && (
        <Button
          disabled={
            editingKey !== "" ||
            (scoreContent && canAdd(data, staffNo, scoreContent.maxItems))
          }
          type="primary"
          onClick={add}
          style={{
            marginBottom: 5,
          }}
          shape="round"
          icon={<PlusOutlined />}
        >
          <FormattedMessage id="add" />
        </Button>
      )}

      {data &&
        data.length > 0 &&
        data.map((list, index) => (
          <Row gutter={24} key={"list" + index}>
            <Col
              xs={24}
              xl={
                list.staff && !isSelfRecord(list.staff.staffNo, staffNo)
                  ? 14
                  : 24
              }
            >
              <Tabs type="card" defaultActiveKey="1">
                <TabPane
                  tab={
                    list.staff ? list.staff.name : <FormattedMessage id="you" />
                  }
                  key="1"
                >
                  <Form form={form} layout="vertical" preserve={false}>
                    <EditableForm
                      isSubmitting={isSubmitting}
                      option={
                        scoreContent && scoreContent.scoreAdjustmentOptionGroup
                      }
                      staffNo={staffNo}
                      principle={principle}
                      form={form}
                      list={list}
                      editingKey={editingKey}
                      editable={isEditing(list)}
                      save={() => save(list.id)}
                      edit={() => edit(list)}
                      cancel={() => cancel(list)}
                      handleDelete={() => handleDelete(list)}
                    />
                  </Form>
                </TabPane>
              </Tabs>
            </Col>
            {list.staff && !isSelfRecord(list.staff.staffNo, staffNo) && (
              <Col xs={24} xl={10}>
                <ReviewList
                  currentList={cutOffCurrent(currentList, list)}
                  refreshScore={refreshScore}
                  listIndex={index}
                  reviews={thisRecord(
                    dataSource,
                    list.scoreAdjustmentNumber,
                    list.id
                  )}
                  scoreAdjustmentNumber={list.scoreAdjustmentNumber}
                  staffNo={staffNo}
                  principle={principle}
                  lastScore={
                    list.scoreAdjustmentOption
                      ? list.scoreAdjustmentOption.id
                      : 0
                  }
                  option={
                    scoreContent && scoreContent.scoreAdjustmentOptionGroup
                  }
                  scoreId={scoreId}
                  appraisalId={appraisalId}
                />
              </Col>
            )}
          </Row>
        ))}

      {data.length > 0 && (
        <Divider className="score-divider" orientation="right">
          <FormattedMessage id="score" />:{" "}
          {currentScore ? currentScore.totalScore : " - "}
        </Divider>
      )}
    </div>
  )
}

export default EditableList
