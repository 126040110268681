import React, { useState, useEffect, useRef, useContext } from "react"
import Seo from "@components/seo"
import MainLayout from "@components/main_layout"
import {
  Steps,
  Button,
  Row,
  Col,
  Form,
  Spin,
  Modal,
  Input,
  Typography,
  Grid,
  message,
} from "antd"
import "@style/appraisal.less"
import {
  ExtraScore,
  CoreScore,
  Preview,
  AppraiseeInfo,
  Comments,
  GoalSetting,
  CurrentScore,
  Degree360,
} from "@components/form"
import Loading from "@components/Loading"
import {
  ExclamationCircleTwoTone,
  ExclamationCircleOutlined,
} from "@ant-design/icons"
import { navigate } from "gatsby"
import {
  AppraisalClient,
  AppraisalTemplateClient,
  CoreCompetencyDefinitionSetClient,
  GoalSettingDefinitionSetClient,
} from "@api"
import { getStaffNo } from "@services/auth"
import { ContextStore } from "@context"
import { FormattedMessage, useIntl } from "react-intl"
import { errorHandle } from "@action"

//TODO: Check Which session has been edited

const Appraisal = ({ id }) => {
  const { locale } = useContext(ContextStore)
  const [previewOnly, setPreviewOnly] = useState(true)
  const staffNo = getStaffNo()
  const [page, setPage] = useState(0)
  const [coreForm] = Form.useForm()
  const [goalForm] = Form.useForm()
  const [commentForm] = Form.useForm()
  const [previewForm] = Form.useForm()
  const [appraisal, setAppraisal] = useState(undefined)
  const [template, setTemplate] = useState(undefined)
  const [sessions, setSessions] = useState([])
  const [isLoad, setIsLoad] = useState(false)
  const [isInit, setIsInit] = useState(true)
  const [scoreDetail, setScoreDetail] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [returnVisible, setReturnVisible] = useState(false)
  const [isChanging, setIsChanging] = useState(false)
  const [validatedSession, setValidatedSession] = useState([])
  const sessionSteps = useRef()
  const [isAping, setIsAping] = useState(false)
  const { formatMessage } = useIntl()

  const handleListClick = async item => {
    const upperString = item.toUpperCase()
    let pageIndex = 0
    switch (true) {
      case upperString.includes("CORE"):
        pageIndex = sessions.findIndex(page => page.id === "coreScore")
        break
      case upperString.includes("COMMENT"):
        pageIndex = sessions.findIndex(page => page.id === "comments")
        break
      case upperString.includes("GOAL"):
        pageIndex = sessions.findIndex(page => page.id === "goalSetting")
        break
      default:
        break
    }
    const newList = sessions.map((_, index) => index)
    setValidatedSession(newList)
    setPage(pageIndex)
  }

  const submitForm = async (id, comment) => {
    const submitFormWarning = formatMessage({ id: "notFinishedForm" })
    const client = new AppraisalClient()
    try {
      await client.edit(id, comment)
      navigate("/my_submission/")
    } catch (err) {
      const errMsg = JSON.parse(err.response)
      message.warning(submitFormWarning)
      handleListClick(errMsg.errors[""][0])
    }
  }

  const confirmSubmit = (id, comment) => {
    const { confirm } = Modal
    const confirmText = locale.includes("zh")
      ? { title: "確認提交評估？", okText: "提交", cancelText: "取消" }
      : {
          title: "Confirm to submit?",
          okText: "Submit",
          cancelText: "Cancel",
        }
    confirm({
      title: confirmText.title,
      okText: confirmText.okText,
      cancelText: confirmText.cancelText,
      icon: <ExclamationCircleOutlined />,
      centered: true,
      onOk() {
        submitForm(id, comment)
      },
      onCancel() {
        setIsLoading(false)
      },
    })
  }

  const handleFormSubmit = async () => {
    window.scrollTo({
      top: sessionSteps.current.offsetTop,
      behavior: "smooth",
    })

    try {
      if (sessions[page].id === "overview") {
        let values = await previewForm.validateFields()
        setIsLoading(true)
        confirmSubmit(appraisal.appraisal.id, {
          id: appraisal.appraisal.id,
          isNext: true,
          comment: values.comment ? values.comment : null,
        })
      }
      if (page < sessions.length - 1) {
        setPage(page + 1)
      }
    } catch (err) {
      setIsLoading(false)
      errorHandle()
    }
  }

  const createSession = (appraisal, template, stepArr, previewOnly) => {
    var newArr = []
    if (!previewOnly) {
      if (
        !!template.coreCompetencyDefinitionSetId &&
        template.coreCompetencyList.length > 0 &&
        stepArr.isNeedUseCoreCompetencies
      ) {
        newArr.push({
          id: "coreScore",
          title: <FormattedMessage id="coreCompetencies" />,
        })
      }
      if (
        !!template.coreCompetencyDefinitionSetId &&
        template.scoreAdjustmentList.length > 0 &&
        stepArr.isNeedUseCoreCompetencies &&
        template.isNormal
      ) {
        newArr.push({
          id: "extraScore",
          title: <FormattedMessage id="additionalScore" />,
        })
      }
      if (template.appraisalTemplateComment.length > 0) {
        if (
          template.appraisalTemplateComment.findIndex(
            item => item.step === stepArr.step
          ) !== -1
        ) {
          newArr.push({
            id: "comments",
            title: <FormattedMessage id="comments" />,
          })
        }
      }
    }
    if (appraisal.subAppraisal !== null) {
      newArr.push({
        id: "degree360",
        title: <FormattedMessage id="degree360" />,
      })
    }
    if (
      !previewOnly &&
      !!template.goalSettingDefinitionSetId &&
      template.goalSettingList.length > 0 &&
      stepArr.isNeedGoalSetting
    ) {
      newArr.push({
        id: "goalSetting",
        title: <FormattedMessage id="goalSetting" />,
      })
    }

    newArr.push({ id: "overview", title: <FormattedMessage id="overview" /> })

    //Complete status

    return newArr
  }

  const refresh = async id => {
    let client = new AppraisalClient()
    setIsLoading(true)
    try {
      let res = await client.get2(id)
      if (res.isSucceeded) {
        setAppraisal(res.content)
        setIsLoading(false)
        setIsChanging(false)
      }
    } catch (err) {
      errorHandle()
    }
  }
  const refreshScore = async () => {
    let client = new AppraisalClient()
    try {
      let res = await client.getScore(id)
      setScoreDetail(res)
    } catch (err) {
      console.log(err)
    }
    try {
      if (validatedSession.includes(page)) {
        switch (true) {
          case sessions[page].id === "coreScore":
            await coreForm.validateFields()
            break
          case sessions[page].id === "comments":
            await commentForm.validateFields()
            break
          case sessions[page].id === "goalSetting":
            await goalForm.validateFields()
            break
          default:
            break
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const InitPage = async id => {
    try {
      const appraisalClient = new AppraisalClient()
      const templateClient = new AppraisalTemplateClient()
      const appraisalRes = await appraisalClient.get2(id)
      setAppraisal(appraisalRes.content)
      setPreviewOnly(!appraisalRes.content.hasEdit)
      const appraisalContent = appraisalRes.content
      let templateRes = await templateClient.get2(
        appraisalContent.appraisal.appraisalTemplateId
      )

      if (!!templateRes.coreCompetencyDefinitionSetId) {
        const coreClient = new CoreCompetencyDefinitionSetClient()
        const result = await coreClient.get2(
          templateRes.coreCompetencyDefinitionSetId
        )

        templateRes = {
          ...templateRes,
          templateType: templateRes.type,
          ...result,
          appraisalTemplateComment: templateRes.appraisalTemplateComment
            .sort((a, b) => a.order - b.order)
            .sort((a, b) => a.step - b.step),

          coreCompetencyList: result.coreCompetencyDefinitions,
          scoreAdjustmentList: result.scoreAdjustmentDefinitions,
        }
      }
      if (!!templateRes.goalSettingDefinitionSetId) {
        const goalClient = new GoalSettingDefinitionSetClient()

        const goalRes = await goalClient.get2(
          templateRes.goalSettingDefinitionSetId
        )
        templateRes = {
          ...templateRes,
          ...goalRes,
          goalSettingList: goalRes.goalSettingDefinitions,
        }
      }
      setTemplate(templateRes)
      setSessions(
        createSession(
          appraisalContent.appraisal,
          templateRes,
          appraisalContent.currentStep,
          !appraisalContent.hasEdit
        )
      )

      const scoreRes = await appraisalClient.getScore(id)
      setScoreDetail(scoreRes)
      setIsInit(false)
      setIsLoad(true)
    } catch (err) {
      navigate("/")
      console.log(err)
    }
  }

  //Enter the submission
  useEffect(() => {
    InitPage(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //renew the new appraisal in here
  useEffect(() => {
    if (!isInit) {
      setIsChanging(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  useEffect(() => {
    if (!isInit) {
      refresh(id)
      refreshScore()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, locale])

  const FormSteps = ({ sessions }) => {
    const { Step } = Steps

    const { xl } = Grid.useBreakpoint()

    const StepTitle = ({ xl, children }) => (
      <span style={{ fontSize: xl ? 16 : 12 }}>{children}</span>
    )

    return (
      <div className="steps" ref={sessionSteps}>
        <Steps
          labelPlacement={xl ? "horizontal" : "vertical"}
          id="pageSteps"
          size="small"
          type="navigation"
          current={page}
          onChange={current => {
            if (!isLoading && !isAping) {
              setPage(current)
            }
          }}
        >
          {sessions.map((session, index) => (
            <Step
              key={index}
              status={page === index ? "process" : "wait"}
              title={<StepTitle xl={xl}>{session.title}</StepTitle>}
            />
          ))}
        </Steps>
      </div>
    )
  }

  const PageButtons = ({ isLoading }) => {
    const buttonProps = {
      block: true,
      shape: "round",
      disabled: isLoading,
    }
    const colProps = {
      xs: 5,
      xl: 4,
      offset: 1,
    }
    const { xl } = Grid.useBreakpoint()

    const getAction = action => {
      if (action.toLowerCase().includes("submit")) {
        return "Submit"
      } else if (action.toLowerCase().includes("review")) {
        return "Review"
      } else if (action.toLowerCase().includes("approve")) {
        return "Approve"
      } else if (action.toLowerCase().includes("acknowledge")) {
        return "Acknowledge"
      }
    }

    const action = appraisal.currentStep
      ? getAction(appraisal.currentStep.action)
      : ""

    return (
      <Row gutter={[24, 12]} justify="center">
        {page > 0 && (
          <Col {...colProps}>
            <Button
              {...buttonProps}
              type="primary"
              onClick={() => {
                window.scrollTo({
                  top: sessionSteps.current.offsetTop,
                  behavior: "smooth",
                })
                setPage(page - 1)
              }}
            >
              <FormattedMessage id="previous" />
            </Button>
          </Col>
        )}

        <Col {...colProps}>
          <Button
            {...buttonProps}
            type={!previewOnly ? "danger" : "default"}
            onClick={() => window.history.back()}
          >
            {!previewOnly ? (
              <FormattedMessage id="cancel" />
            ) : (
              <FormattedMessage id="back" />
            )}
          </Button>
        </Col>
        {previewOnly && page + 1 < sessions.length && (
          <Col {...colProps}>
            <Button
              {...buttonProps}
              type="primary"
              onClick={() => {
                window.scrollTo({
                  top: sessionSteps.current.offsetTop,
                  behavior: "smooth",
                })

                setPage(page + 1)
              }}
            >
              <FormattedMessage id="next" />
            </Button>
          </Col>
        )}
        {!previewOnly && appraisal.currentStep.isHaveReturn && (
          <Col {...colProps}>
            <Button
              style={{ backgroundColor: "#858585" }}
              {...buttonProps}
              type="primary"
              onClick={() => {
                setReturnVisible(true)
              }}
            >
              {xl ? (
                <FormattedMessage id="return" />
              ) : (
                <FormattedMessage id="returnForXs" />
              )}
            </Button>
          </Col>
        )}
        {!previewOnly && (
          <Col {...colProps}>
            <Button
              {...buttonProps}
              type="primary"
              onClick={() => {
                if (!isAping) {
                  handleFormSubmit()
                }
              }}
            >
              {page + 1 < sessions.length ? (
                <FormattedMessage id="next" />
              ) : (
                <FormattedMessage id={action} />
              )}
            </Button>
          </Col>
        )}
      </Row>
    )
  }

  const ReturnModal = ({ visible, setVisible }) => {
    const [form] = Form.useForm()
    const { TextArea } = Input

    const returnAlert = formatMessage({ id: "returnAlert" })

    const { Text } = Typography

    const [isLoading, setIsLoading] = useState(false)
    const onFinish = async values => {
      try {
        setIsLoading(true)
        await submitForm(appraisal.appraisal.id, {
          id: appraisal.appraisal.id,
          isNext: false,
          comment: values.comment,
        })
      } catch (err) {
        errorHandle()
      } finally {
        setIsLoading(false)
      }
    }

    return (
      <Modal
        title={
          <>
            <ExclamationCircleTwoTone twoToneColor="red" />
            &nbsp;
            <FormattedMessage id="returnModal" />
          </>
        }
        centered
        destroyOnClose={true}
        visible={visible}
        okText={<FormattedMessage id="returnv2" />}
        cancelText={<FormattedMessage id="cancel" />}
        onCancel={() => setVisible(false)}
        onOk={() => form.submit()}
        confirmLoading={isLoading}
      >
        <Form form={form} onFinish={onFinish}>
          <Form.Item>
            <Text>{returnAlert}</Text>
          </Form.Item>

          <Form.Item
            name="comment"
            rules={[
              {
                required: true,
                message: "Please write down your comment before return",
              },
            ]}
          >
            <TextArea
              disabled={isLoading}
              placeholder={formatMessage({ id: "returnReason" })}
              autoSize={{ minRows: 5, maxRows: 10 }}
            />
          </Form.Item>
        </Form>
      </Modal>
    )
  }

  return !isInit && !isChanging ? (
    <MainLayout
      isForm
      subHeader={
        <AppraiseeInfo
          degree360Weighting={template?.degree360Weighting}
          coreCompetencyWeighting={template?.coreCompetencyWeighting}
          siteKPIWeighting={template?.siteKPIWeighting}
          appraisal={appraisal}
          score={scoreDetail}
          isNormal={template?.isNormal}
          templateType={template?.templateType}
          
        />
      }
    >
      <Seo title="Appraise Form" />
      <FormSteps sessions={sessions} />
      <Spin spinning={isLoading} size="large">
        <ReturnModal
          setIsLoading={isLoad => {
            setIsLoading(isLoad)
          }}
          visible={returnVisible}
          setVisible={value => setReturnVisible(value)}
        />

        {isLoad ? (
          sessions[page].id === "coreScore" &&
          appraisal.currentStep &&
          appraisal.currentStep.isNeedUseCoreCompetencies ? (
            <CoreScore
              refreshScore={() => {
                refreshScore()
              }}
              isNormal={template.isNormal}
              appraisee={appraisal.appraisal}
              staffNo={staffNo}
              appraisalId={id}
              ratingScale={template.ratingScale}
              principles={template.coreCompetencyList}
              record={appraisal.appraisal.appraisalCoreCompetency}
              form={coreForm}
              stepList={appraisal.path}
              currentStep={appraisal.currentStep}
            />
          ) : sessions[page].id === "extraScore" &&
            appraisal.currentStep &&
            appraisal.currentStep.isNeedUseCoreCompetencies ? (
            <ExtraScore
              refreshScore={() => {
                refreshScore()
              }}
              currentScore={scoreDetail}
              appraisalId={id}
              staffNo={staffNo}
              record={appraisal.appraisal.appraisalScoreAdjustment}
              principles={template.scoreAdjustmentList}
              siteKPI={appraisal.appraisal.siteKPIScore}
              stepList={appraisal.path}
              currentStep={appraisal.currentStep}
            />
          ) : sessions[page].id === "comments" ? (
            <Comments
              commentStep={template.appraisalTemplateComment}
              staffNo={staffNo}
              form={commentForm}
              appraisalId={id}
              record={appraisal.appraisal.appraisalComment}
              principles={template.appraisalTemplateComment}
              currentStep={appraisal.currentStep.step}
            />
          ) : sessions[page].id === "degree360" ? (
            <Degree360
              record={appraisal.appraisal.subAppraisal}
              score={scoreDetail?.degree360Score}
            />
          ) : sessions[page].id === "goalSetting" &&
            appraisal.currentStep &&
            appraisal.currentStep.isNeedGoalSetting ? (
            <GoalSetting
              setIsAping={setIsAping}
              period={appraisal.appraisal.appraisalCycle}
              appraisee={appraisal.appraisal}
              form={goalForm}
              staffNo={staffNo}
              principles={template.goalSettingList}
              guideLine={template.goalSettingGuideLine}
              appraisalId={parseInt(id)}
              record={appraisal.appraisal.appraisalGoalSetting}
            />
          ) : sessions[page].id === "overview" ? (
            <Preview
              templateType={template.templateType}
              appraisalId={id}
              isLoading={isLoading}
              commentStep={template.appraisalTemplateComment}
              additionalScore={scoreDetail?.scoreAdjustmentGroup}
              period={appraisal.appraisal.appraisalCycle}
              form={previewForm}
              stepList={appraisal.path}
              currentStep={appraisal.currentStep}
              isNeedComment={
                appraisal.currentStep &&
                appraisal.currentStep.isNeedReviewComment &&
                !previewOnly
              }
              corePrinciples={template.coreCompetencyList}
              coreRecord={appraisal.appraisal.appraisalCoreCompetency}
              extraPrinciples={template.scoreAdjustmentList}
              extraRecord={appraisal.appraisal.appraisalScoreAdjustment}
              commentsPrinciples={template.appraisalTemplateComment}
              commentRecord={appraisal.appraisal.appraisalComment}
              goalPrinciples={template.goalSettingList}
              goalRecord={appraisal.appraisal.appraisalGoalSetting}
              staffNo={staffNo}
              remark={appraisal.appraisal.appraisalHistory}
              appraisee={appraisal.appraisal}
              isNormal={template.isNormal}
            />
          ) : null
        ) : (
          <Loading />
        )}
        <CurrentScore 
          score={scoreDetail} 
          isNormal={template.isNormal} 
          degree360Weighting={template?.degree360Weighting}
          coreCompetencyWeighting={template?.coreCompetencyWeighting}
          siteKPIWeighting={template?.siteKPIWeighting}
        />
        <PageButtons isLoading={isLoading} />
      </Spin>
    </MainLayout>
  ) : (
    <Loading />
  )
}

export default Appraisal
