import React, { useEffect, useState } from "react"
import { Table, message, Space } from "antd"
import { FormattedMessage } from "react-intl"
import { AppraisalApprovalPathSnapshotsClient } from "@api"

const PathTable = React.memo(({ id }) => {
  const [dataSource, setDataSource] = useState([])
  const columns = [
    {
      title: "",
      dataIndex: ["appraisalTemplateApprovalPath", "step"],
      render: (_, record, index) => index + 1,
    },
    {
      title: <FormattedMessage id="role" />,
      dataIndex: ["appraisalTemplateApprovalPath", "role"],
    },
    {
      title: <FormattedMessage id="staffName" />,
      dataIndex: "snapshotStaffs",
      render: staffs => (
        <Space direction="vertical">
          {staffs.map(({ staff }) => (
            <span key={staff?.staffNo}>{staff?.name}</span>
          ))}
        </Space>
      ),
    },
    {
      title: <FormattedMessage id="action" />,
      dataIndex: ["appraisalTemplateApprovalPath", "name"],
    },
  ]

  useEffect(() => {
    const fetchData = async id => {
      try {
        const client = new AppraisalApprovalPathSnapshotsClient()
        const result = await client.get(id)
        result.sort(
          (a, b) =>
            a.appraisalTemplateApprovalPath.step -
            b.appraisalTemplateApprovalPath.step
        )
        setDataSource(result)
      } catch (error) {
        message.error("系統發生錯誤")
      }
    }

    fetchData(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Table
        rowKey="id"
        size="small"
        pagination={false}
        columns={columns}
        dataSource={dataSource}
        scroll={{ y: 300 }}
      />
    </div>
  )
})

export default PathTable
