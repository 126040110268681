import React, { useContext, useState } from "react"
import {
  Typography,
  Divider,
  Row,
  Col,
  Form,
  Upload,
  Statistic,
  Tabs,
  Space,
  Input,
  Comment,
  Descriptions,
  Empty,
  Button,
  Grid,
} from "antd"
import "./preview.less"
import {
  translate,
  tranformFile,
  PrinciplesDescription,
  getPrincipleName,
  getPrincipleRecord,
  getPrincipleType,
  transformDate,
  getFiles,
} from "@action"
import NoAmendEmpty from "@components/NoAmendEmpty"
import {
  AppraisalScoreAdjustmentClient,
  AppraisalCommentClient,
  AppraisalCoreCompetencyClient,
  TemplateType,
} from "@api"
import { ContextStore } from "@context"
import { FormattedMessage, useIntl } from "react-intl"
import GoalModal from "../GoalModal"
import { ReadOutlined } from "@ant-design/icons"

const CoreReviewArea = ({
  defId,
  isReview,
  record,
  maxScore,
  currentList,
  templateType,
}) => {
  const handleDownload = async file => {
    let client = new AppraisalCoreCompetencyClient()
    try {
      let res = await client.attachment(file.uid)
      let url = window.URL.createObjectURL(res.data)
      let a = document.createElement("a")
      a.href = url
      a.download = res.fileName
      a.click()
      a.remove()
    } catch (err) {
      console.log(err)
    }
  }
  const { Paragraph } = Typography
  const { TabPane } = Tabs

  const thisRecords = record.filter(
    item => item.coreCompetencyDefinitionId === defId
  )

  const selfRecord = thisRecords.length > 0 ? thisRecords[0] : null

  let showRecord = []
  let lastAvg = selfRecord ? selfRecord.avgRating : 0
  currentList.forEach(item => {
    let currAvg
    let isHvRecord = thisRecords.findIndex(record => record.step === item.step)
    if (isHvRecord !== -1) {
      currAvg = thisRecords[isHvRecord].avgRating
    } else {
      currAvg = null
    }
    let isMoreThanOne = item.approver.length > 1
    item.approver.forEach(approver => {
      let index = thisRecords.findIndex(
        record =>
          record.staff.staffNo === approver.staffNo && record.step === item.step
      )
      if (index !== -1) {
        let thisShowRecord = thisRecords[index]
        if (!isMoreThanOne) {
          thisShowRecord = { ...thisShowRecord, avgRating: null }
        }
        showRecord.push(thisShowRecord)
      } else {
        showRecord.push({
          content: "No Adjustment",
          rating: lastAvg,
          avgRating: currAvg,
          noAmend: true,
          staff: {
            name: approver.name,
          },
        })
      }
    })
    if (currAvg !== null) {
      lastAvg = currAvg
    }
  })

  return (
    <Row justify="space-between">
      <Col xl={isReview ? 12 : 24} xs={24}>
        <Tabs size="small" defaultActiveKey="0" type="card">
          {selfRecord ? (
            <TabPane tab={selfRecord.staff.name}>
              <Row align="space-between">
                {templateType !== TemplateType.Degree360 ? (
                  <Col span={16}>
                    <Paragraph
                      ellipsis={{ rows: 4, expandable: true, symbol: "more" }}
                    >
                      <pre>{selfRecord.content ?? " - "}</pre>
                    </Paragraph>
                    <Upload
                      onPreview={handleDownload}
                      showUploadList={{
                        showDownloadIcon: false,
                        showRemoveIcon: false,
                      }}
                      fileList={tranformFile(
                        selfRecord.appraisalCoreCompetencyFile,
                        "AppraisalCoreCompetency"
                      )}
                    />
                  </Col>
                ) : (
                  <Col span={16} />
                )}

                <Col pull={1}>
                  <Space
                    className="rating-space"
                    direction="vertical"
                    size="small"
                  >
                    {selfRecord.rating || selfRecord.rating === 0 ? (
                      <Statistic
                        title={<FormattedMessage id="rating" />}
                        value={selfRecord.rating}
                        suffix={"/ " + maxScore}
                      />
                    ) : null}
                  </Space>
                </Col>
              </Row>
            </TabPane>
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<FormattedMessage id="noRecord" />}
            />
          )}
        </Tabs>
      </Col>

      {isReview && (
        <Col xl={11} xs={24}>
          <Tabs
            size="small"
            defaultActiveKey={"key" + (showRecord.length - 1)}
            type="card"
          >
            {showRecord &&
              showRecord.length > 0 &&
              showRecord.map((item, index) => (
                <TabPane tab={item.staff.name} key={"key" + index}>
                  <Row align="space-between">
                    <Col span={16}>
                      {item.noAmend ? (
                        <NoAmendEmpty />
                      ) : (
                        <Paragraph
                          ellipsis={{
                            rows: 4,
                            expandable: true,
                            symbol: "more",
                          }}
                        >
                          <pre>{item.content ?? " - "}</pre>
                        </Paragraph>
                      )}
                    </Col>
                    {(item.rating || item.rating === 0) && (
                      <Col pull={1}>
                        <Space
                          className="rating-space"
                          direction="vertical"
                          size="small"
                        >
                          <Statistic
                            title={<FormattedMessage id="rating" />}
                            value={item.rating}
                            suffix={"/ " + maxScore}
                          />

                          {item.avgRating !== null ? (
                            <Statistic
                              title={<FormattedMessage id="avgRating" />}
                              value={item.avgRating}
                              suffix={"/ " + maxScore}
                            />
                          ) : null}
                        </Space>
                      </Col>
                    )}
                  </Row>
                </TabPane>
              ))}
          </Tabs>
        </Col>
      )}
    </Row>
  )
}

const CoreReviewContainer = ({
  principle,
  index,
  coreRecord,
  appraisee,
  staffNo,
  isNormal,
  currentList,
  appraisalId,
  templateType,
}) => {
  const isReview = currentList.length > 0
  const { locale } = useContext(ContextStore)
  const [goalModal, setGoalModal] = useState(false)
  return (
    <>
      <Col span={24}>
        <Divider orientation="left" key={index}>
          {index + 1}. {translate(principle.name, locale)}
        </Divider>
      </Col>
      <Col span={24}>
        <PrinciplesDescription
          key={"descriptions" + index}
          description={translate(principle.description, locale)}
        />
        {principle.hasGoalsSet && (
          <Button
            shape="round"
            size="small"
            icon={<ReadOutlined />}
            onClick={() => {
              setGoalModal(true)
            }}
          />
        )}
      </Col>
      <Col span={24}>
        {coreRecord && coreRecord.length > 0 ? (
          <CoreReviewArea
            currentList={currentList}
            maxScore={principle.score}
            defId={principle.coreCompetencyDefinitionId}
            isReview={isReview && isNormal}
            record={coreRecord}
            appraisee={appraisee}
            staffNo={staffNo}
            templateType={templateType}
          />
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<FormattedMessage id="noRecord" />}
          />
        )}
      </Col>
      <GoalModal
        visible={goalModal}
        onCancel={() => setGoalModal(false)}
        appraisalId={appraisalId}
        locale={locale}
      />
    </>
  )
}
const ExtraRecord = ({ record }) => {
  const { TabPane } = Tabs
  const { xl } = Grid.useBreakpoint()
  const handleDownload = async file => {
    let client = new AppraisalScoreAdjustmentClient()
    try {
      let res = await client.attachment(file.uid)
      let url = window.URL.createObjectURL(res.data)
      let a = document.createElement("a")
      a.href = url
      a.download = res.fileName
      a.click()
      a.remove()
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <Tabs
      size="small"
      defaultActiveKey={"key0"}
      type="card"
      className="list-descriptions"
    >
      <TabPane tab={record.staff.name} key={"key0"}>
        <Descriptions size="small" column={24} layout="vertical" colon={false}>
          <Descriptions.Item span={2} label={<FormattedMessage id="item" />}>
            {record.item + 1}
          </Descriptions.Item>
          <Descriptions.Item span={6} label={<FormattedMessage id="title" />}>
            {record.title}
          </Descriptions.Item>
          <Descriptions.Item
            span={xl ? 7 : 16}
            label={<FormattedMessage id="description" />}
          >
            <pre>{record.description ? record.description : " - "}</pre>
          </Descriptions.Item>
          <Descriptions.Item
            span={xl ? 6 : 8}
            label={<FormattedMessage id="attachment" />}
          >
            <Upload
              onPreview={handleDownload}
              showUploadList={{ showRemoveIcon: false }}
              fileList={tranformFile(
                record.appraisalScoreAdjustmentFile,
                "AppraisalScoreAdjustment"
              )}
            ></Upload>
          </Descriptions.Item>
          <Descriptions.Item
            span={xl ? 3 : 16}
            label={<FormattedMessage id="score" />}
          >
            {record.scoreAdjustmentOption
              ? record.scoreAdjustmentOption.score
              : 0}
          </Descriptions.Item>
        </Descriptions>
      </TabPane>
    </Tabs>
  )
}

const AmendList = ({ currentList, oldRecord }) => {
  let record = []
  const handleDownload = async file => {
    let client = new AppraisalScoreAdjustmentClient()
    try {
      let res = await client.attachment(file.uid)
      let url = window.URL.createObjectURL(res.data)
      let a = document.createElement("a")
      a.href = url
      a.download = res.fileName
      a.click()
      a.remove()
    } catch (err) {
      console.log(err)
    }
  }

  let lastIndex = 0
  currentList.forEach(item => {
    item.approver.forEach(approver => {
      let index = oldRecord.findIndex(
        record =>
          record.staff.staffNo === approver.staffNo && record.step === item.step
      )
      if (index !== -1) {
        record.push({ ...oldRecord[index], isAmend: true })
        lastIndex = index
      } else {
        record.push({
          isAmend: false,
          scoreAdjustmentOption: oldRecord[lastIndex].scoreAdjustmentOption,
          staff: {
            name: approver.name,
          },
        })
      }
    })
  })
  const { TabPane } = Tabs
  const { xl } = Grid.useBreakpoint()
  return record && record.length > 0 ? (
    <Col xs={24} sm={24} xl={10}>
      <Tabs
        type="card"
        size="small"
        defaultActiveKey={"key" + (record.length - 1)}
        className="list-descriptions"
      >
        {record.map((item, index) => (
          <TabPane tab={item.staff.name} key={"key" + index}>
            <Descriptions
              size="small"
              column={24}
              layout="vertical"
              colon={false}
            >
              {item.isAmend ? (
                <>
                  <Descriptions.Item
                    span={xl ? 11 : 24}
                    label={<FormattedMessage id="comment" />}
                  >
                    <pre>{item.description ? item.description : " - "}</pre>
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={xl ? 10 : 21}
                    label={<FormattedMessage id="attachment" />}
                  >
                    <Upload
                      onPreview={handleDownload}
                      showUploadList={{
                        showDownloadIcon: false,
                        showRemoveIcon: false,
                      }}
                      fileList={tranformFile(
                        item.appraisalScoreAdjustmentFile,
                        "AppraisalCoreCompetency"
                      )}
                    ></Upload>
                  </Descriptions.Item>
                </>
              ) : (
                <Descriptions.Item span={21}>
                  <NoAmendEmpty />
                </Descriptions.Item>
              )}

              <Descriptions.Item
                span={3}
                label={<FormattedMessage id="score" />}
              >
                {item.scoreAdjustmentOption ? (
                  item.scoreAdjustmentOption.score
                ) : (
                  <FormattedMessage id="invalid" />
                )}
              </Descriptions.Item>
            </Descriptions>
          </TabPane>
        ))}
      </Tabs>
    </Col>
  ) : null
}

const cutOffCurrent = (currentList, record) => {
  let newList = [...currentList]

  const newIndex = newList.findIndex(item => item.step === record.step)

  return newList.splice(newIndex + 1)
}

const ExtraReviewArea = ({ currentList, principle, extraRecord }) => {
  const data = extraRecord.filter(
    item =>
      item.scoreAdjustmentDefinitionId === principle.scoreAdjustmentDefinitionId
  )

  const originData = data
    .filter(item => item.title !== null)
    .map((item, index) => ({ ...item, item: index }))

  const amendRecord = (arr, scoreAdjustmentNumber) => {
    return arr.filter(
      item => item.scoreAdjustmentNumber === scoreAdjustmentNumber
    )
  }

  return originData.length > 0 ? (
    originData.map(item => (
      <>
        <Col xs={24} sm={24} xl={currentList.length > 0 ? 14 : 24}>
          <ExtraRecord record={item} />
        </Col>
        <AmendList
          currentList={cutOffCurrent(currentList, item)}
          oldRecord={amendRecord(extraRecord, item.scoreAdjustmentNumber)}
        />
      </>
    ))
  ) : (
    <Col span={24}>
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<FormattedMessage id="noRecord" />}
      />
    </Col>
  )
}

const CommentReviewArea = ({ type, id, record }) => {
  const handleDownload = async file => {
    let client = new AppraisalCommentClient()
    try {
      let res = await client.attachment(file.uid)
      let url = window.URL.createObjectURL(res.data)
      let a = document.createElement("a")
      a.href = url
      a.download = res.fileName
      a.click()
      a.remove()
    } catch (err) {
      console.log(err)
    }
  }
  let list = record.filter(item => item.commentDefinitionId === id)

  return list && list.length > 0 ? (
    list.map(item => (
      <Comment
        author={item.staff.name}
        content={
          type === 0 ? (
            <pre>{item.content}</pre>
          ) : type === 1 ? (
            transformDate(item.content)
          ) : (
            <Upload
              size="small"
              onPreview={handleDownload}
              showUploadList={{ showRemoveIcon: false }}
              fileList={getFiles(item)}
            />
          )
        }
      />
    ))
  ) : (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={<FormattedMessage id="noRecord" />}
    />
  )
}

const GoalsReviewArea = ({ goalRecord, id }) => {
  const { TabPane } = Tabs
  const { locale } = useContext(ContextStore)
  const { lg } = Grid.useBreakpoint()
  let record = getPrincipleRecord(goalRecord, "goalDefinitionId", id)

  return record.length > 0 ? (
    <Tabs
      size="small"
      defaultActiveKey={"goalkey" + (record.length - 1)}
      type="card"
    >
      {record.map((item, index) => (
        <TabPane tab={item.staff.name} key={"goalkey" + index}>
          <Descriptions column={2} layout="vertical">
            <Descriptions.Item
              span={lg ? 1 : 2}
              label={<FormattedMessage id="goals" />}
            >
              <pre>{item.goals}</pre>
            </Descriptions.Item>
            <Descriptions.Item
              span={lg ? 1 : 2}
              label={<FormattedMessage id="plan" />}
            >
              <pre>{item.plan}</pre>
            </Descriptions.Item>
            <Descriptions.Item
              span={lg ? 1 : 2}
              label={<FormattedMessage id="trainingPlan" />}
            >
              {item.trainingAndDevelopmentPlanOther
                ? item.trainingAndDevelopmentPlanOther
                : translate(item.goalSettingPlans, locale)}
            </Descriptions.Item>
            <Descriptions.Item
              span={lg ? 1 : 2}
              label={<FormattedMessage id="expectedResults" />}
            >
              <pre>{item.expectedResults}</pre>
            </Descriptions.Item>
          </Descriptions>
        </TabPane>
      ))}
    </Tabs>
  ) : (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={<FormattedMessage id="noRecord" />}
    />
  )
}

const getTotalScore = (arr, defId) => {
  if (arr.length > 0) {
    return arr.find(item => item.defId === defId).totalScore
  } else {
    return " - "
  }
}

const Preview = ({
  commentStep,
  templateType,
  additionalScore,
  form,
  isNeedComment,
  corePrinciples,
  coreRecord,
  extraPrinciples,
  extraRecord,
  commentsPrinciples,
  commentRecord,
  goalPrinciples,
  goalRecord,
  staffNo,
  appraisee,
  isNormal,
  stepList,
  currentStep,
  period,
  remark,
  isLoading,
  appraisalId,
}) => {
  const { Title } = Typography
  const { locale } = useContext(ContextStore)
  const { formatMessage } = useIntl()

  const remarkPlaceholder = formatMessage({ id: "textAreaReviewComment" })

  const remarkRecord = remark.filter(item => item.remarks !== null)

  let isMyStep = currentStep
    ? stepList[stepList.length - 1].approver.findIndex(
        item => item.staffNo === staffNo
      )
    : -1

  let activeIndex = stepList.findIndex(item => item.status === "Active")

  let currentList = stepList.slice(
    1,
    isMyStep !== -1
      ? stepList.length
      : activeIndex !== -1
      ? activeIndex
      : stepList.length
  )

  let currentCommentSteps = commentStep.filter(
    item =>
      item.step <=
      (isMyStep !== -1
        ? currentStep.step
        : activeIndex !== -1
        ? activeIndex - 1
        : stepList.length)
  )

  currentList = currentList.filter(item => item.isNeedUseCoreCompetencies)

  const { TextArea } = Input
  const transformRecord = (arr, id) => {
    return arr.filter(item => item.scoreAdjustmentDefinitionId === id)
  }

  return (
    <Row gutter={(12, 12)} className="preview">
      <Col span={24}>
        <Title className="preview-title" level={3}>
          <FormattedMessage id="coreCompetencies" />
        </Title>
      </Col>

      {corePrinciples &&
        corePrinciples.length > 0 &&
        corePrinciples.map((principle, index) => (
          <CoreReviewContainer
            templateType={templateType}
            appraisalId={appraisalId}
            key={"core" + index}
            currentList={currentList}
            principle={principle}
            index={index}
            coreRecord={coreRecord}
            appraisee={appraisee}
            staffNo={staffNo}
            isNormal={isNormal}
          />
        ))}
      {isNormal && extraPrinciples && extraPrinciples.length > 0 && (
        <Col span={24}>
          <Title className="preview-title" level={3}>
            <FormattedMessage id="additionalScore" />
          </Title>
        </Col>
      )}
      {isNormal &&
        extraPrinciples &&
        extraPrinciples.length > 0 &&
        extraPrinciples.map((principle, index) => (
          <>
            <Col span={24}>
              <Divider orientation="left" key={"extra" + index}>
                {index + 1 + ". " + translate(principle.name, locale)}
              </Divider>
            </Col>
            <Col span={24}>
              <PrinciplesDescription
                key={"descriptions" + index}
                description={translate(principle.description, locale)}
              />
            </Col>

            <ExtraReviewArea
              key={"extra" + index}
              currentList={currentList}
              principle={principle}
              index={index}
              extraRecord={transformRecord(
                extraRecord,
                principle.scoreAdjustmentDefinitionId
              )}
            />

            <Divider className="score-divider" orientation="right">
              <FormattedMessage id="score" />
              {" : " +
                getTotalScore(
                  additionalScore,
                  principle.scoreAdjustmentDefinitionId
                )}
            </Divider>
          </>
        ))}
      {commentsPrinciples && commentsPrinciples.length > 0 && (
        <Col span={24}>
          <Title className="preview-title" level={3}>
            <FormattedMessage id="comments" />
          </Title>
        </Col>
      )}
      {currentCommentSteps &&
        currentCommentSteps.length > 0 &&
        currentCommentSteps.map((item, index) => (
          <Col span={24}>
            <Divider orientation="left" key={index}>
              {index +
                1 +
                ". " +
                getPrincipleName(commentsPrinciples, "id", item.id, locale)}
            </Divider>

            <CommentReviewArea
              type={getPrincipleType(commentsPrinciples, "id", item.id)}
              id={item.commentDefinitionId}
              record={commentRecord}
            />
          </Col>
        ))}

      {remarkRecord &&
        remarkRecord.length > 0 &&
        remarkRecord.map((item, index) => (
          <Col span={24}>
            <Divider orientation="left" key={index}>
              {index + currentCommentSteps.length + 1 + ". "}
              {item.content == "Return for Amendment" ?        
                <FormattedMessage
                  id="returningFormAmendment"
                  values={{ staff: item.role }}
                />
              : 
                <FormattedMessage
                 id="remarkCommentTitle"
                 values={{ staff: item.role }}
                />
              }
            </Divider>
            <Comment author={item.staff} content={item.remarks} />
          </Col>
        ))}

      {goalPrinciples && goalPrinciples.length > 0 && (
        <>
          <Col span={24}>
            <Title className="preview-title" level={3}>
              <FormattedMessage id="goalSetting" />
            </Title>
          </Col>
          <Col span={24}>
            <Divider orientation="left">
              <FormattedMessage id="goalPeriod" />
              {transformDate(period.goalStartDate) +
                " to " +
                transformDate(period.goalEndDate)}
            </Divider>
          </Col>
        </>
      )}
      {!isLoading &&
        goalPrinciples &&
        goalPrinciples.length > 0 &&
        goalPrinciples.map((principle, index) => (
          <Col span={24}>
            <Divider orientation="left" key={index}>
              {index + 1}. {translate(principle.name, locale)}
            </Divider>

            <GoalsReviewArea
              key={"goalReview" + index}
              goalRecord={goalRecord}
              id={principle.goalSettingDefinitionId}
            />
          </Col>
        ))}
      <Col span={24}>
        <Form form={form}>
          {isNeedComment && isNormal && (
            <>
              <Title className="preview-title" level={3}>
                <FormattedMessage id="remarks" />
                {" / "}
                <FormattedMessage id="comments" />
              </Title>

              <Form.Item name="comment">
                <TextArea
                  autoSize={{ minRows: 5, maxRows: 10 }}
                  placeholder={remarkPlaceholder}
                />
              </Form.Item>
            </>
          )}
        </Form>
      </Col>
    </Row>
  )
}

export default Preview
