import React from "react"
import { Descriptions } from "antd"
import { FormattedMessage } from "react-intl"
import { transformDate } from "@action"

const PersonalDescription = React.memo(
  ({ person, attendanceRecord, appraisalCycle, isNormal }) => {
    return (
      <Descriptions
        title={<FormattedMessage id="apprisaeeInfo" />}
        layout="vertical"
        size="small"
        className="person-descriptions"
      >
        <Descriptions.Item label={<FormattedMessage id="fullName" />}>
          {person.name}
        </Descriptions.Item>
        {isNormal && (
          <Descriptions.Item label={<FormattedMessage id="staffNo" />}>
            {person.staffNo}
          </Descriptions.Item>
        )}
        <Descriptions.Item label={<FormattedMessage id="company" />}>
          {person.company}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="site" />}>
          {person.siteOrDepartment}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="position" />}>
          {person.position}
        </Descriptions.Item>
        {isNormal && (
          <Descriptions.Item label={<FormattedMessage id="dateJoined" />}>
            {transformDate(person.dateJoined)}
          </Descriptions.Item>
        )}
        {attendanceRecord && isNormal && (
          <>
            <Descriptions.Item
              label={
                <FormattedMessage
                  id="lateness"
                  values={{ name: <FormattedMessage id="times" /> }}
                />
              }
            >
              {attendanceRecord.latenessTimes + ""}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <FormattedMessage
                  id="lateness"
                  values={{ name: <FormattedMessage id="mins" /> }}
                />
              }
            >
              {attendanceRecord.latenessMinutes + ""}
            </Descriptions.Item>
            <Descriptions.Item label={<FormattedMessage id="failToClock" />}>
              {attendanceRecord.failingToClock + ""}
            </Descriptions.Item>
          </>
        )}
        {isNormal && (
          <Descriptions.Item
            label={<FormattedMessage id="yearInCurrentPosition" />}
          >
            {person.yearInCurrentPosition || person.yearInCurrentPosition === 0
              ? person.yearInCurrentPosition + ""
              : "N/A"}
          </Descriptions.Item>
        )}
        <Descriptions.Item
          label={<FormattedMessage id="reviewPeriod" />}
          span={2}
        >
          {transformDate(appraisalCycle.appraisalStartDate) +
            " to " +
            transformDate(appraisalCycle.appraisalEndDate)}
        </Descriptions.Item>
      </Descriptions>
    )
  }
)

export default PersonalDescription
