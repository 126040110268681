import React, { useState, useEffect, useContext } from "react"
import {
  Typography,
  Tabs,
  Button,
  Divider,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Upload,
  Statistic,
  Space,
  message,
} from "antd"
import {
  UploadOutlined,
  ReadOutlined,
  EditOutlined,
  CheckCircleTwoTone,
  LoadingOutlined,
} from "@ant-design/icons"
import "./core_score.less"
import { AppraisalCoreCompetencyClient } from "@api"
import {
  translate,
  tranformFile,
  PrinciplesDescription,
  getInputRecord,
  getOtherRecord,
} from "@action"
import NoAmendEmpty from "@components/NoAmendEmpty"
import { ContextStore } from "@context"
import { FormattedMessage, useIntl } from "react-intl"
import { errorHandle } from "@action"
import GoalModal from "../GoalModal"
import moment from "moment"

//Get Someone record

const handleDownload = async file => {
  let client = new AppraisalCoreCompetencyClient()
  try {
    let res = await client.attachment(file.uid)
    let url = window.URL.createObjectURL(res.data)
    let a = document.createElement("a")
    a.href = url
    a.download = res.fileName
    a.click()
    a.remove()
  } catch (err) {
    errorHandle()
  }
}

const ReviewSession = ({ history, editor, maxScore }) => {
  const { TabPane } = Tabs
  const { Paragraph } = Typography
  return (
    <Tabs size="small" defaultActiveKey="0" type="card">
      <TabPane tab={editor}>
        <Row align="space-between">
          <Col span={16}>
            <Paragraph ellipsis={{ rows: 4, expandable: true, symbol: "more" }}>
              <pre>{history.content ? history.content : " - "}</pre>
            </Paragraph>
            <Upload
              onPreview={handleDownload}
              showUploadList={{
                showDownloadIcon: false,
                showRemoveIcon: false,
              }}
              fileList={tranformFile(
                history.appraisalCoreCompetencyFile,
                "AppraisalCoreCompetency"
              )}
            />
          </Col>
          <Col pull={1}>
            <Space className="rating-space" direction="horizontal" size="small">
              <Statistic
                title={<FormattedMessage id="rating" />}
                value={history.rating}
                suffix={"/ " + maxScore}
              />
            </Space>
          </Col>
        </Row>
      </TabPane>
    </Tabs>
  )
}

const getShowRecord = (arr, defId, currentList) => {
  const thisRecords = arr.filter(
    item => item.coreCompetencyDefinitionId === defId
  )

  let showRecord = []

  let lastAvg = thisRecords[0].avgRating

  currentList.forEach(item => {
    item.approver.forEach(approver => {
      let index = thisRecords.findIndex(
        record => record.staff.staffNo === approver.staffNo
      )
      if (index !== -1) {
        showRecord.push(thisRecords[index])
      } else {
        showRecord.push({
          content: "No Adjustment",
          noAmend: true,
          rating: lastAvg,
          staff: {
            name: approver.name,
          },
        })
      }
    })
    let isHvRecord = thisRecords.findIndex(record => record.step === item.step)
    if (isHvRecord !== -1) {
      lastAvg = thisRecords[isHvRecord].avgRating
    }
  })

  return showRecord
}

const ReviewInput = ({
  currentList,
  refreshScore,
  staffNo,
  record,
  appraisalId,
  coreId,
  maxScore,
  index,
}) => {
  const { TabPane } = Tabs
  const { Paragraph } = Typography
  const otherRecords = getShowRecord(record, coreId, currentList)
  const selfRecord = getInputRecord(
    record,
    "coreCompetencyDefinitionId",
    coreId,
    staffNo,
    true
  )
  const thisRecord = getOtherRecord(
    record,
    "coreCompetencyDefinitionId",
    coreId,
    "",
    staffNo
  )
  const [activeKey, setActiveKey] = useState(
    otherRecords.length > 0 && !selfRecord
      ? "key" + (otherRecords.length - 1)
      : "amend"
  )

  const [isAmend, setIsAmemd] = useState(
    selfRecord || otherRecords.length < 1 ? true : false
  )
  const [newUpdateId, setNewUpdateId] = useState(
    selfRecord ? selfRecord.id : null
  )

  const handleEdit = async action => {
    setIsAmemd(!isAmend)
    try {
      if (action === "add") {
        setActiveKey("amend")
      } else {
        if (newUpdateId !== null) {
          let client = new AppraisalCoreCompetencyClient(
            process.env.REACT_APP_URL
          )
          let res = await client.delete(newUpdateId)
          if (res.isSucceeded) {
            setNewUpdateId(null)
            refreshScore()
          }
        }
        setActiveKey("key" + (otherRecords.length - 1))
      }
    } catch (err) {
      errorHandle()
    }
  }

  return (
    <Tabs
      addIcon={
        <>
          <EditOutlined />
          <FormattedMessage id="edit" />
        </>
      }
      hideAdd={isAmend}
      type={otherRecords.length > 0 ? "editable-card" : "card"}
      size="small"
      activeKey={activeKey}
      onChange={activeKey => {
        setActiveKey(activeKey)
      }}
      onEdit={(_, action) => handleEdit(action)}
    >
      {otherRecords.length > 0 &&
        otherRecords.map((record, index) => (
          <TabPane tab={record.staff.name} closable={false} key={"key" + index}>
            <Row align="space-between">
              <Col span={16}>
                {record.noAmend ? (
                  <NoAmendEmpty />
                ) : (
                  <Paragraph
                    ellipsis={{ rows: 4, expandable: true, symbol: "more" }}
                  >
                    <pre>{record.content ? record.content : " - "}</pre>
                  </Paragraph>
                )}
              </Col>
              {record.rating && (
                <Col pull={1}>
                  <Statistic
                    title={<FormattedMessage id="rating" />}
                    value={record.rating}
                    suffix={"/ " + maxScore}
                  />
                </Col>
              )}
            </Row>
          </TabPane>
        ))}
      {isAmend && (
        <TabPane tab={<FormattedMessage id="you" />} key="amend">
          <InputArea
            refreshScore={refreshScore}
            history={
              selfRecord
                ? selfRecord
                : {
                    content: null,
                    rating: thisRecord[thisRecord.length - 1].rating,
                    id: null,
                  }
            }
            appraisalId={appraisalId}
            coreId={coreId}
            maxScore={maxScore}
            index={index}
            lastScore={thisRecord[thisRecord.length - 1].rating}
            newUpdateId={newUpdateId}
            setNewUpdateId={setNewUpdateId}
            isAmend={true}
          />
        </TabPane>
      )}
    </Tabs>
  )
}

const InputArea = ({
  isNormal = true,
  refreshScore,
  appraisalId,
  uploadAble,
  index,
  maxScore,
  coreId,
  history,
  newUpdateId,
  setNewUpdateId,
  isAmend,
  lastScore,
}) => {
  const { TextArea } = Input
  const { formatMessage } = useIntl()
  const { Title, Text } = Typography
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isTouch, setIsTouch] = useState(false)
  const [content, setContent] = useState(history ? history.content : null)
  const [rating, setRating] = useState(history ? history.rating : null)
  const [updateId, setUpdateId] = useState(history ? history.id : null)
  const [fileList, setFileList] = useState(
    history && history.appraisalCoreCompetencyFile
      ? tranformFile(
          history.appraisalCoreCompetencyFile,
          "AppraisalCoreCompetency"
        )
      : []
  )
  const [saveTime, setSaveTime] = useState(null)

  const [oldFileList, setOldFileList] = useState(
    history && history.appraisalCoreCompetencyFile
      ? tranformFile(
          history.appraisalCoreCompetencyFile,
          "AppraisalScoreAdjustment"
        )
      : []
  )

  const handleSubmit = async () => {
    setIsSubmitting(true)
    let client = new AppraisalCoreCompetencyClient()
    let result
    let files =
      fileList.length > 0
        ? fileList.map(item => {
            return { data: item.originFileObj, fileName: item.name }
          })
        : null
    try {
      if (!content && rating === lastScore) throw new Error("notChange")
      if (!isAmend ? updateId === null : newUpdateId === null) {
        result = await client.create(
          content,
          coreId,
          appraisalId,
          rating,
          files
        )
        if (!isAmend) {
          setUpdateId(result.id)
        } else {
          setNewUpdateId(result.id)
        }
      } else {
        console.log("updateData")
        let newList = []
        fileList.forEach(item => {
          if ("url" in item) {
            newList.push(item)
          }
        })

        let removedList = []

        oldFileList.forEach(oldItem => {
          if (newList.findIndex(newItem => newItem.uid === oldItem.uid) === -1)
            removedList.push(oldItem.uid)
        })

        let newUploadList = []
        fileList.forEach(item => {
          if ("size" in item) {
            newUploadList.push({
              data: item.originFileObj,
              fileName: item.name,
            })
          }
        })
        result = await client.update(
          !isAmend ? updateId : newUpdateId,
          content,
          rating,
          newUploadList,
          removedList
        )
      }
      setIsTouch(false)
      refreshScore()
      setFileList(
        tranformFile(
          result.appraisalCoreCompetencyFile,
          "AppraisalCoreCompetency"
        )
      )
      setOldFileList(
        tranformFile(
          result.appraisalCoreCompetencyFile,
          "AppraisalCoreCompetency"
        )
      )
    } catch (err) {
      if (err.message === "notChange") {
        try {
          if (newUpdateId !== null) {
            await client.delete(newUpdateId)
            setNewUpdateId(null)
            refreshScore()
          }
        } catch (err) {
          errorHandle()
        }
      } else {
        errorHandle()
      }
    } finally {
      const obj = {
        date: moment().format("YYYY-MM-DD"),
        time: moment().format("HH:mm:ss"),
      }
      setSaveTime(obj)
      setIsSubmitting(false)
    }
  }

  const onChange = ({ file: newFile, fileList: newFileList }) => {
    const isValid =
      newFile.type === "image/png" ||
      newFile.type === "image/gif" ||
      newFile.type === " image/jpg" ||
      newFile.type === "image/jpeg" ||
      newFile.type === "application/pdf" ||
      newFile.type === "text/plain"
    const isLt5M = newFile.size / 1024 / 1024 < 5
    if (
      (newFile.status && newFile.status === "removed") ||
      (isLt5M && isValid)
    ) {
      setIsTouch(true)
      setFileList([...newFileList])
    } else if (!isValid) {
      message.error("The file type of " + newFile.name + " is not accepted")
    } else if (!isLt5M) {
      message.error(newFile.name + " cannot be larger than 5 Mbs")
    }
  }

  useEffect(() => {
    if (isTouch) {
      handleSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, rating, fileList])

  const textAreaHolder = formatMessage({ id: "textAreaReviewComment" })

  const ratingWarning = formatMessage(
    { id: "pleaseInput" },
    { name: <FormattedMessage id="rating" /> }
  )

  return (
    <Row align="middle">
      <Col span={18}>
        <Form.Item
          name={[index, "comment"]}
          onBlur={e => {
            setIsTouch(true)
            setContent(e.target.value)
          }}
          initialValue={content}
          hidden={!isNormal}
        >
          <TextArea
            disabled={isSubmitting}
            autoSize={{ minRows: 5, maxRows: 10 }}
            placeholder={textAreaHolder}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          label={<FormattedMessage id="rating" />}
          className="number-div"
        >
          <Input.Group compact>
            <Form.Item
              name={[index, "rating"]}
              initialValue={rating}
              onBlur={e => {
                setIsTouch(true)
                let rating = parseInt(e.target.value)
                rating = rating > maxScore ? maxScore : rating < 0 ? 0 : rating
                setRating(rating)
              }}
              rules={[{ required: true, message: ratingWarning }]}
            >
              <InputNumber
                disabled={isSubmitting}
                className="number-field"
                parser={value => parseInt(value)}
                min={0}
                pattern="\d+"
                max={maxScore}
                type="number"
                size="large"
              />
            </Form.Item>
            <Form.Item>
              <Title level={3}>{"/" + maxScore}</Title>
            </Form.Item>
          </Input.Group>
        </Form.Item>
      </Col>

      <Col span={14}>
        <Form.Item
          extra={
            <Text type="secondary">
              <FormattedMessage id="maxFileSize" values={{ name: "5MB" }} />
              <br />
              <FormattedMessage
                id="fileType"
                values={{ name: "png gif jpg jpeg pdf txt" }}
              />
            </Text>
          }
          hidden={!uploadAble || !isNormal}
        >
          <Upload
            size="small"
            onPreview={handleDownload}
            beforeUpload={() => false}
            onChange={onChange}
            fileList={fileList}
            accept=".png,  .gif, .jpg, .jpeg, .pdf, .txt"
          >
            {fileList.length < 3 && (
              <Button disabled={isSubmitting}>
                <UploadOutlined />
                <FormattedMessage
                  id="upload"
                  values={{ name: formatMessage({ id: "attachment" }) }}
                />
              </Button>
            )}
          </Upload>
        </Form.Item>
      </Col>
      <Col span={10}>
        <Row justify="end">
          {isSubmitting ? (
            <LoadingOutlined spin />
          ) : (
            <>
              <Col>
                {saveTime ? (
                  <FormattedMessage id="lastSavedOn" values={saveTime} />
                ) : null}
              </Col>
              <Col offset={1}>
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              </Col>
            </>
          )}
        </Row>
      </Col>
    </Row>
  )
}

const CoreScore = ({
  appraisee,
  appraisalId,
  ratingScale,
  principles,
  form,
  record,
  staffNo,
  refreshScore,
  isNormal,
  stepList,
}) => {
  const [goalModal, setGoalModal] = useState(false)
  const review = appraisee.staffNo !== staffNo && isNormal
  const reviewProps = {
    xs: 24,
    xl: 12,
  }

  const props = {
    xs: 24,
    xl: 12,
  }

  let currentList = stepList.slice(1, stepList.length - 1)
  currentList = currentList.filter(item => item.isNeedUseCoreCompetencies)
  const { locale } = useContext(ContextStore)
  return (
    <>
      <div
        style={{ width: "100%" }}
        dangerouslySetInnerHTML={{ __html: ratingScale }}
      />
      <Row gutter={12} className="core-score">
        <Form
          layout="inline"
          colon={false}
          name="coreform"
          form={form}
          preserve={false}
        >
          {principles &&
            principles.length > 0 &&
            principles.map((principle, index) => (
              <>
                <Col span={24}>
                  <Divider key={"divider" + index} orientation="left">
                    {index + 1}. {translate(principle.name, locale)}
                  </Divider>
                </Col>
                <Col xs={24} lg={review ? 24 : 12}>
                  <PrinciplesDescription
                    key={"descriptions" + index}
                    description={translate(principle.description, locale)}
                  />
                  {principle.hasGoalsSet && (
                    <Button
                      shape="round"
                      size="small"
                      icon={<ReadOutlined />}
                      onClick={() => {
                        setGoalModal(true)
                      }}
                    />
                  )}
                </Col>
                {review ? (
                  <>
                    <Col {...props}>
                      <ReviewSession
                        key={"ReviewSession" + index}
                        maxScore={principle.score}
                        history={getInputRecord(
                          record,
                          "coreCompetencyDefinitionId",
                          principle.coreCompetencyDefinitionId,
                          appraisee.staffNo,
                          true
                        )}
                        editor={appraisee.name}
                      />
                    </Col>
                    <Col {...reviewProps}>
                      <ReviewInput
                        currentList={currentList}
                        refreshScore={refreshScore}
                        staffNo={staffNo}
                        key={"ReviewInput" + index}
                        editor={appraisee}
                        record={record}
                        index={index}
                        appraisalId={appraisalId}
                        coreId={principle.coreCompetencyDefinitionId}
                        maxScore={principle.score}
                      />
                    </Col>
                  </>
                ) : (
                  <Col {...props}>
                    <InputArea
                      isNormal={isNormal}
                      refreshScore={refreshScore}
                      history={getInputRecord(
                        record,
                        "coreCompetencyDefinitionId",
                        principle.coreCompetencyDefinitionId,
                        staffNo,
                        true
                      )}
                      appraisalId={appraisalId}
                      coreId={principle.coreCompetencyDefinitionId}
                      uploadAble
                      key={"InputArea" + index}
                      maxScore={principle.score}
                      index={index}
                    />
                  </Col>
                )}
              </>
            ))}
        </Form>
      </Row>
      <GoalModal
        visible={goalModal}
        onCancel={() => setGoalModal(false)}
        appraisalId={appraisalId}
        locale={locale}
      />
    </>
  )
}

export default CoreScore
